.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
  }
  
  @media(min-width:576px) {
    .container {
      max-width: 540px
    }
  }
  
  @media(min-width:768px) {
    .container {
      max-width: 720px
    }
  }
  
  @media(min-width:992px) {
    .container {
      max-width: 960px
    }
  }
  
  @media(min-width:1200px) {
    .container {
      max-width: 1140px
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
  }
  
  .home-section {
    margin-top: 80px;
    margin-bottom: 80px
  }
  
  button {
    font-family: inherit;
    font-size: 100%;
    margin: 0
  }
  
  img {
    height: auto;
    max-width: 100%
  }
  
  .text-center {
    text-align: center
  }
  
  .mw-900 {
    max-width: 900px!important;
  }
  
  @media (min-width: 576px) {
    .box_action {
      margin: 1.75rem auto;
    }
  }
  
  .box-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #14181d;
    border-radius: 24px;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .1);
  }
  
  @media (min-width: 340px) {
    .py-lg-10 {
      padding-top: 2rem!important;
      padding-bottom: 2rem!important;
    }
    .py-lg-5 {
      padding-top: 1.25rem!important;
      padding-bottom: 1.25rem!important;
    }
    .px-lg-15 {
      padding-right: 3.75rem!important;
      padding-left: 3.75rem!important;
    }
  }
  
  .w-100 {
    width: 100%!important;
  }
  
  .text-center {
    text-align: center!important;
  }
  
  .mb-3 {
    margin-bottom: .75rem!important;
  }
  
  h1 {
    font-size: 1.75rem;
  }
  
  .text-muted {
    color: #a1a5b7!important;
  }
  
  .fw-bold {
    font-weight: 500!important;
    margin-bottom: 1rem!important;
  }
  
  .fs-5 {
    font-size: 1.15rem!important;
  }
  
  .fw-bolder {
    font-weight: 600!important;
  }
  
  .link-primary {
    color: #6f7faf;
  }
  
  a {
    --tw-gradient-from: #bb28cf;
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to,rgba(187,40,207,0));
    --tw-gradient-to: #9c34fc;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    color: transparent;
    text-decoration: none;
  }
  
  .cad>img {
    width: 127px;
    margin: auto;
    border-radius: 20%;
  }
  
  img {
    vertical-align: middle;
  }
  
  .text-center {
    text-align: center!important;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .flex-stack {
    justify-content: space-between;
    align-items: center;
  }
  
  .mb-5 {
    margin-bottom: 1.25rem!important;
  }
  
  .d-flex {
    display: flex!important;
  }
  
  label {
    display: inline-block;
  }
  
  .me-2 {
    margin-right: .5rem!important;
  }
  
  .align-items-center {
    align-items: center!important;
  }
  
  .me-6 {
    margin-right: 1.5rem!important;
  }
  
  .cad .cad-label {
    width: 50px;
    height: 50px;
  }
  
  .cad .cad-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    border-radius: .475rem;
  }
  
  .bg-light-primary {
    background-color: #333942d9!important;
  }
  
  .hw-icon {
    line-height: 1;
  }
  
  .hw-icon.hw-icon-1 img {
    height: 1.75rem!important;
  }
  
  .flex-column {
    flex-direction: column!important;
  }
  
  .fs-6 {
    font-size: 1.075rem!important;
  }
  
  .fs-7 {
    font-size: .95rem!important;
  }
  
  .op-check.op-check-custom {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
  }
  
  .op-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 2.25rem;
    margin-bottom: .125rem;
  }
  
  .op-check.op-check-solid .op-check-input {
    border: 0;
    background-color: #3f4854;
  }
  
  .op-check.op-check-custom .op-check-input {
    margin: 0;
    float: none;
    flex-shrink: 0;
  }
  
  .op-check .op-check-input {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .op-check-input[type=radio] {
    border-radius: 50%;
  }
  
  .op-check .op-check-input {
    float: left;
    margin-left: -2.25rem;
  }
  
  .op-check-input {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -.125rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    color-adjust: exact;
    transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  
  button,
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  .op-check.op-check-solid .op-check-input:checked {
    background-color: #67759f;
  }
  
  .op-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
  }
  
  .mb-4 {
    margin-bottom: 1rem!important;
  }
  
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }
  
  .mt-2 {
    margin-top: .5rem!important;
  }
  
  @media (min-width: 1200px) {
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
  }
  
  .flex-center {
    justify-content: center;
    align-items: center;
  }
  
  .box-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    padding: 1.5rem;
    border-top: 2px solid #333b44;
  }
  
  .btn.btn-light {
    color: #b3b3b3;
    border-color: #333b44;
    background-color: #333b44;
  }
  
  .btn.btn-spaces {
    color: #b3b3b3;
    border-color: #14181d;
    background-color: #14181d;
  }
  
  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(.58rem + 1px) calc(1.5rem + 1px);
  }
  
  .btn {
    outline: 0!important;
  }
  
  .me-3 {
    margin-right: .75rem!important;
  }
  
  .btn {
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: .475rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  
  .btn.btn-primary {
    color: #fff;
    background-image: linear-gradient(to right, #41485a 0%, #67759f 51%, #606b8d 100%);
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn.btn-second {
    color: white;
    background-image: linear-gradient(to right, #0095e8 0%, #024468 51%, #267aa9 100%);
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn.btn-cancel {
    color: white;
    background-image: linear-gradient(to right, #e94f4f 0%, #540404 51%, #d75f5ffa 100%);
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .box-header {
    padding: 1.75rem 1.75rem;
  }
  
  @media (min-width: 1200px) {
    .mx-xl-18 {
      margin-right: 4.5rem!important;
      margin-left: 4.5rem!important;
    }
  }
  
  .pt-0 {
    padding-top: 0!important;
  }
  
  .box-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.75rem;
  }
  
  .mb-15 {
    margin-bottom: 1rem!important;
  }
  
  .scroll-y {
    overflow-y: scroll;
    position: relative;
  }
  
  .pe-7 {
    padding-right: 1.75rem!important;
  }
  
  .me-n7 {
    margin-right: -1.75rem!important;
  }
  
  .mh-375 {
    max-height: 342px!important;
  }
  
  .flex-stack {
    justify-content: space-between;
    align-items: center;
  }
  
  .border-gray-300 {
    border-color: #e4e6ef!important;
  }
  
  .py-5 {
    padding-top: 1.25rem!important;
    padding-bottom: 1.25rem!important;
  }
  
  .border-bottom {
    border-bottom: 2px solid #333b44!important;
    border-bottom-style: dashed!important;
  }
  
  .cad.cad-35px>img {
    width: 64px;
    margin-bottom: 6px;
  }
  
  .ms-6 {
    margin-left: 1.5rem!important;
  }
  
  .txt-item_ {
    color: #dbdbdb!important;
  }
  
  .text-end {
    text-align: right!important;
  }