.is-align-center {
  align-items: center
}

.relative__hb,
.relative__hb_hu,
.relative__hb_ro,
.relative__hb_eq {
  position: relative
}

.is-text-capitalized {
  text-transform: capitalize
}

.is-text-semi {
  font-family: Poppins, sans-serif!important
}

.is-text-regular {
  font-family: Poppins, sans-serif!important;
  font-weight: 600!important
}

.ranft {
  color: #33a1d1!important
}

.is-color-lightGrey {
  color: #bdc0c2
}

.mt-2 {
  margin-top: 10px!important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
}

.mt-4 {
  margin-top: 30px!important
}

.mb-3 {
  margin-bottom: 15px!important
}

.pt-3 {
  padding-top: 15px!important
}

.pb-5 {
  padding-bottom: 30px!important
}

.gap-10 {
  grid-gap: 10px;
  gap: 10px
}

.is-full-width {
  width: 100%!important
}

[class^=icon-] {
  height: 14px;
  width: 14px;
  display: inline-block;
  fill: currentColor
}

.pagination-left:after {
  content: "<"
}

.pagination-right:after {
  content: ">"
}

.pagination-ellipsis:after {
  content: "…"
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

@media (min-width:1280px) {
  .inventory_nft[vphawk] {
    position: relative
  }
}

.inventory_nft .inventory-assets[vphawk] {
  max-width: 1210px;
  margin: 100px auto 0
}

@media (max-width:1490px) {
  .inventory_nft .inventory-assets[vphawk] {
    max-width: 870px
  }
}

@media (max-width:940px) {
  .inventory_nft .inventory-assets[vphawk] {
    max-width: 730px
  }
}

@media (min-width:0px) and (max-width:767px) {
  .inventory_nft .inventory-assets[vphawk] {
    padding: 0 16px;
    max-width: 100%;
    margin-top: 50px
  }
}

.inventory_nft .inventory-assets .filter-options[vphawk] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px
}

@media (min-width:0px) and (max-width:1279px) {
  .inventory_nft .inventory-assets .filter-options[vphawk] {
    margin: 20px 0 0
  }
}

.sort-by-filter[vphawk] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: unset;
  left: unset;
  z-index: 2
}

.sort-by-filter .sort-by-title[vphawk] {
  height: 40px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
}

.sort-by-filter .sort-by-title p[vphawk] {
  margin-right: 10px;
  font-size: 13px;
  line-height: 16px;
  font-family: "Montserrat Regular";
  color: #a0a4a7;
  text-align: center
}

.sort-by-filter .custom-dropdown[vphawk] {
  width: 158px
}

.card .multi-button {
  z-index: 0;
  position: absolute;
  top: 2.8rem;
  left: 1.8rem;
}

.card .multi-button button {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  background: rgb(79, 70, 229);
  color: #ffffff;
  transform: translate(-50%, -50%);
  cursor: pointer;
  box-shadow: 0 0 0rem -0.25rem #5d9cec;
}

.card .multi-button button:hover {
  background: #ffffff;
  color: #46567a;
  box-shadow: 0 0 1rem -0.25rem #46567a;
}

.drop-btn[filwert],
.drop-btn_ro,
.drop-btn_hu,
.drop-btn_hule {
  font-size: 13px
}

.drop-btn[filwert],
.drop-btn_ro,
.drop-btn_hu,
.drop-btn_hule {
  width: auto;
  height: 30px;
  border-radius: 20px;
  background-color: #2f3740;
  color: #fff;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}

.drop-btn.squared[filwert],
.drop-btn_ro.squared,
.drop-btn_hu.squared,
.drop-btn_hule.squared {
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.drop-btn svg[filwert],
.drop-btn_ro svg .drop-btn_hu svg,
.drop-btn_hule svg {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: ease
}

.inventory-astar .title[text-vd7] {
  font-size: 22px
}

.inventory-astar .assets-wrapper[text-vd7],
.inventory-astar .assets-wrapper_hu[text-vd7],
.inventory-astar .assets-wrapper_ro[text-vd7],
.inventory-astar .assets-wrapper_eq[text-vd7] {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 254px);
  grid-gap: 34px;
  gap: 34px;
  justify-content: center
}

@media (min-width:0px) and (max-width:767px) {
  .inventory-astar .assets-wrapper[text-vd7] {
    grid-template-columns: repeat(auto-fill, 46%);
    grid-column-gap: 14px;
    -moz-column-gap: 14px;
    column-gap: 14px;
    grid-row-gap: 20px;
    row-gap: 20px
  }
}

.card[estin_] {
  position: relative;
  width: 100%;
  height: 356px;
  border-radius: 10px;
  background: linear-gradient(180deg, #2d3640 0, #29384a);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.card .card-top[estin_] {
  overflow: hidden;
  flex: 1 1 0;
}

.card .card-top figure[estin_] {
  flex: 1 1 0;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card .card-top figure .card-rocket[estin_] {
  height: 248px;
  width: 268px;
  -o-object-fit: contain;
  object-fit: contain;
}

.card .card-top figure .card-preview[estin_] {
  height: 234px;
  width: 182px;
  -o-object-fit: contain;
  object-fit: contain;
  filter: brightness(1.5);
}

.card .card-bottom[estin_] {
  padding: 15px 10px 10px;
}

.card .card-bottom .card-name[estin_] {
  font-size: 18px;
  line-height: 22px;
  font-family: Titles, sans-serif;
  color: #fff;
  text-align: left;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .card-bottom-data[estin_] {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.card .card-bottom-data .level-txth[estin_] {
  font-size: 14px;
  line-height: 18px;
  font-family: Poppins, sans-serif!important;
  color: #fff;
  text-align: center;
}

.card .card-bottom-data .card-exp[estin_] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card .card-bottom-data .gap-10[estin_] {
  grid-gap: 10px;
  gap: 10px;
}

.card .card-bottom-data .card-exp img[estin_] {
  width: 25px;
  height: 28px;
}

.card .card-bottom-data .card-exp .exp-number[estin_] {
  font-size: 16px;
  line-height: 26px;
  font-family: Poppins, sans-serif!important;
  color: #c19e5d;
  text-align: left;
  font-weight: 600!important;
}

.pagination-container[pag-vh],
.pagination-container_hu[pag-vh],
.pagination-container_ro[pag-vh],
.pagination-container_eq[pag-vh] {
  width: 100%;
  height: 29px;
  margin: 20px 0;
  justify-content: center;
}

.page-item-hu,
.page-item-ro,
.page-item-eq {
  list-style-type: none;
  cursor: pointer;
}

.page-item-hu a,
.page-item-ro a,
.page-item-eq a {
  text-decoration: none;
  color: #a0a4a7;
}

.pagination-container_hu>.page-item-hu,
.pagination-container_ro>.page-item-ro,
.pagination-container_eq>.page-item-eq {
  background-color: transparent;
  border: none;
  color: #a0a4a7;
  margin: 0 7px;
  width: 29px;
  height: 29px;
  min-width: 29px;
  min-height: 29px;
  border-radius: 28%;
  line-height: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination-container_hu>.page-item-hu:hover.active-hu,
.pagination-container_ro>.page-item-ro:hover.active-ro,
.pagination-container_eq>.page-item-eq:hover.active-eq {
  color: #fff;
  background-color: #282e36;
}

.pagination-container_hu>.page-item-hu:hover,
.pagination-container_ro>.page-item-ro:hover,
.pagination-container_eq>.page-item-eq:hover {
  background-color: #555d62;
}

.pagination-container_hu>.page-item-hu.active-hu,
.pagination-container_ro>.page-item-ro.active-ro,
.pagination-container_eq>.page-item-eq.active-eq {
  color: #fff;
  background-color: #555d62;
}

.head_ivt {
  padding: 40px 2px 10px;
}

@media (min-width: 768px) {
  .head_ivt {
    padding: 0 20px;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    height: 70px;
  }
}

.vp__tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -20px;
  padding-left: 0;
}

.vp__tabs li {
  margin-top: 15px;
  margin-right: 15px;
}

.vp__tabs li:last-child {
  margin-right: 0;
}

.vp__tabs a {
  font-family: Titles, sans-serif;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  color: #bdbdbd;
  font-size: 16px;
  background-color: #252c38;
  padding: 0 18px;
  height: 32px;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 19px 100%, 0 calc(100% - 20px));
}

.vp__tabs a:hover {
  color: #fff;
}

.vp__tabs a.active {
  color: #fff;
  background-color: #46567a;
}

@media (min-width: 768px) {
  .vp__tabs li {
    margin-top: 20px;
    margin-right: 20px;
  }
  .vp__tabs li:last-child {
    margin-right: 0;
  }
}

.content_inventory {
  display: none;
}

.content_inventory.active {
  display: block;
}

.filters_inv {
  display: none;
}

.filters_inv.active {
  display: block;
}

.contain_opro .cllasro,
.contain_ophu .cllashu,
.contain_ophule .cllashule {
  opacity: 0;
}

.icon-arrow-dropdown_ro,
.icon-arrow-dropdown_hu,
.icon-arrow-dropdown_hule {
  height: 14px;
  width: 14px;
  display: inline-block;
  fill: currentColor;
  transition: all 0.4s ease;
}

.cllasro,
.cllashu,
.cllashule {
  width: auto;
  height: 40px;
  background-color: #2f3740;
  color: #fff;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-100%);
  pointer-events: none;
  cursor: pointer;
  transition: all 0.4s ease;
}

.contain_opro.active .cllasro,
.contain_ophu.active .cllashu,
.contain_ophule.active .cllashule {
  opacity: 1;
  transform: translateY(0%);
}

.contain_opro .cllasro:last-child,
.contain_ophu .cllashu:last-child,
.contain_ophule .cllashule:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cllasro span,
.cllashu span,
.cllashule span {
  font-family: Poppins, sans-serif!important;
  font-size: 13px;
}

.icon-arrow-dropdown_ro.active,
.icon-arrow-dropdown_hu.active,
.icon-arrow-dropdown_hule.active {
  transform: rotate(180deg);
}

.cont_formeq {
  display: grid;
  padding: 12px;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 0.4fr 0.2fr 0.4fr 0.2fr 0.4fr 0.4fr;
  grid-auto-columns: 1fr;
  gap: 9px 0px;
  grid-auto-flow: row;
  grid-template-areas: "equip_name" "name_txt" "rocket_sele" "selecte_rock" "human_sele" "selecte_huma" "count_equip";
}

.name_reqin {
  width: 100%;
  height: 35px;
  background-color: #4a5569;
  border-radius: 8px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  padding-left: 10px;
  font-weight: 400;
  letter-spacing: .03rem;
}

.name_reqin::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.name_reqin:focus {
  outline: none;
  padding-left: 10px;
  border: 1px solid #69a3b9;
}

multi-input {
  display: inline-block;
  border: 2px solid #4a5569;
  border-radius: 8px;
  display: block;
  overflow: hidden;
  padding: 5px;
}

.rockets_slin {
  height: 26px;
  border-radius: 5px;
  width: 140px;
  background-color: #4a5569;
}

.humans_slin {
  height: 26px;
  border-radius: 5px;
  width: 140px;
  background-color: #4a5569;
}

.equip_name {
  grid-area: equip_name;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.equip_name label {
  font-family: Titles, sans-serif;
  font-size: 22px;
}

.name_txt {
  grid-area: name_txt;
}

.rocket_sele {
  grid-area: rocket_sele;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rocket_sele label {
  font-family: Titles, sans-serif;
  font-size: 22px;
}

.selecte_rock {
  grid-area: selecte_rock;
}

.human_sele {
  grid-area: human_sele;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.human_sele label {
  font-family: Titles, sans-serif;
  font-size: 22px;
}

.selecte_huma {
  grid-area: selecte_huma;
}

.count_equip {
  display: grid;
  margin-top: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "coun_human count_rock coun_exp full_xd";
  grid-area: count_equip;
}

.coun_human {
  grid-area: coun_human;
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #4a5569;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.coun_human span {
  margin-left: 4px;
}

.count_rock {
  grid-area: count_rock;
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #4a5569;
  border-top: 2px solid #4a5569;
  border-right: 2px solid #4a5569;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.count_rock span {
  margin-left: 4px;
}

.coun_exp {
  grid-area: coun_exp;
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #4a5569;
  border-top: 2px solid #4a5569;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.full_xd {
  grid-area: full_xd;
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #4a5569;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.crd .crd-bottom {
  -webkit-backdrop-filter: blur(34px);
  backdrop-filter: blur(34px);
  clip-path: polygon( 0 0, calc(100% - 0px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
}

.crd__img-container {
  width: 100%;
  overflow: hidden
}

.crd.admin {
  background: radial-gradient(57.58% 43.37% at 50% 50%, #132035 0, #15181f 100%)
}

.crd.robot {
  background: radial-gradient(57.58% 43.37% at 50% 50%, #9f6045 0, #15181f 100%);
}

.crd.academic {
  background: radial-gradient(57.58% 43.37% at 50% 50%, #276960 0, #15181f 100%);
  border-radius: 16px;
}

.crd.engineer {
  background: radial-gradient(57.58% 43.37% at 50% 50%, #6258a4 0, #15181f 100%);
  border-radius: 16px;
}

.crd.boss {
  background: radial-gradient(91.41% 50% at 50% 50%, #995f72 0, #15181f 71.6%);
  border-radius: 16px;
}

.card-body {
  padding: 8px 8px 0!important;
  margin-bottom: -18px
}

.crd {
  width: 260px;
  height: 424px;
  background: #15181f;
  border-radius: 16px 16px 0 0!important;
}

.crd video {
  width: 100%;
  border-radius: 8px
}

.crd-taginf {
  width: 100%;
  margin-top: 16px;
  padding-left: 4px;
}

.crd-tag {
  padding: 3px 12px 3px 12px!important;
  background: hsla(0, 0%, 100%, .15);
  border-radius: 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 600!important;
}

.crd-count {
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  line-height: 16px;
}

.crd-title {
  margin-top: 10px;
  padding-left: 8px;
  padding-right: 8px
}

.crd-title h3 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  letter-spacing: .06em
}

.crd-mainimg {
  z-index: 2;
}

.crd-bottom {
  width: 260px;
  height: 56px;
  margin-left: -9px;
  margin-top: 30px;
  background: #1c1c21;
  -webkit-backdrop-filter: blur(34px);
  backdrop-filter: blur(34px);
  border-radius: 0 0 10px
}

.crd-bottom-sec1 {
  max-width: 54px;
  margin-left: 30px
}

.crd-bottom-btn {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 3px 8px 3px 8px!important;
  background: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 8px;
  font-weight: 500;
  color: #1c1c21!important;
  padding: 0
}

.crd-bottom-btn img {
  width: 14px;
  margin-top: -2px
}

.crd-bottom-btn:hover {
  background: #fafafa;
  border: 1px solid #fafafa
}

.crd-tag.label {
  margin-right: 10px
}

.blck-btn {
  border: 1px solid #0e1216!important
}

.blck-btn,
.blck-btn:hover {
  background-color: #0e1216;
  color: #fff!important
}

.sldr-crd-rght {
  margin-right: 16px
}

@media (max-width:576px) {
  .d-flex {
    flex-direction: column
  }
  .crd-bottom-sec1 {
    flex-direction: row
  }
}

button {
  outline: none
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y)*-1);
  margin-right: calc(var(--bs-gutter-x)*-0.5);
  margin-left: calc(var(--bs-gutter-x)*-0.5);
}

.row {
  flex: 1 1 auto;
  margin: -12px;
}

.v-application .align-self-start {
  align-self: flex-start!important;
}

.v-application .d-flex {
  display: flex!important;
}

.col {
  flex: 1 0 0%;
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)*0.5);
  padding-left: calc(var(--bs-gutter-x)*0.5);
  margin-top: var(--bs-gutter-y);
}

.v-application .align-self-center {
  align-self: center!important;
}

.v-application .align-self-end {
  align-self: flex-end!important;
}

.sldr-crd-rght {
  margin-right: 16px;
}

.nft-title {
  margin-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.nft-title h3 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  letter-spacing: .06em;
  text-align: center;
}

.card .card-fee-data[estin_] {
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card .card-fee-data .fdes[estin_] {
  font-size: 14px;
  line-height: 18px;
  font-family: Poppins, sans-serif!important;
  color: #fff;
  text-align: center;
}

.crd-tags_nft {
  width: 100%;
  margin-top: -34px;
  padding-left: 28px;
}

.crd-tag_nft {
  padding: 3px 12px 3px 12px!important;
  background: hsl(271deg 19% 10% / 75%);
  border-radius: 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 600!important;
}

.data_hw {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.flex-start-center,
.flex-ston {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-crit {
  align-items: flex-end;
}

.data_hw-price {
  font-size: 20px;
  display: flex;
  margin-bottom: 1px;
  font-weight: 600;
}

.top-2 {
  top: 2.2px!important;
}

.ds_2 {
  margin-right: 5px!important;
}

.token_ssap {
  height: 24px;
  position: relative;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
  min-width: 80%;
}

.flex {
  display: flex;
}

.w-full {
  width: 100%;
}

.mt-3 {
  margin-top: .75rem;
}

.actb.buy {
  background-image: linear-gradient(to right, #439189 0%, #2f704f 51%, #4ba58c 100%);
}

.actb.approve {
  background-image: linear-gradient(to right, #e53935 0%, #e35d5b 51%, #e53935 100%);
}

.actb {
  width: 105px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  height: 30px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.actb:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.actb i {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}




.inventory-content {
  flex: 1 1;
  width: 100vw;
  height: calc(100vh - 28px);
  overflow: auto;
  overflow-x: hidden
}

@media (min-width:768px) and (max-width:1279px) {
  .inventory-content {
    height: calc(100vh - 76px)
  }
}

@media (min-width:0px) and (max-width:767px) {
  .inventory-content {
    height: calc(100vh - 45px)
  }
}

@media (min-width:0px) and (max-width:1279px) {
  .inventory-content {
    margin-top: 0
  }
}

.hw-main {
  padding: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto
}

html {
  overflow-x:hidden !important;

}



img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  font-style: italic;
  vertical-align: middle
}

a:focus,
button:focus {
  outline: 0!important
}

a:-webkit-any-link {
  text-decoration: none
}

a:visited {
  color: #00adef
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
}

h3 {
  font-size: 25px;
  font-family: Titles, sans-serif;
}

@media (min-width:1367px) and (max-width:1600px) {
  h3 {
    font-size: 20px
  }
}

p {
  font-size: 15px;
  line-height: 20px;
  color: #bdc0c2
}

@media (min-width:1367px) and (max-width:1600px) {
  p {
    font-size: 12px
  }
}

@media (min-width:0px) and (max-width:767px) {
  p {
    font-size: 11px;
    line-height: 13px
  }
}

a {
  text-decoration: none
}

hr.light[data-v-0833e527] {
  background-color: #505861;
}

hr.horizontal[data-v-0833e527] {
  width: 100%;
  height: 1px;
}

hr[data-v-0833e527] {
  border: 0;
  margin: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #f5f5f5
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5
}

::-webkit-scrollbar-thumb {
  background-color: #14181d;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

.is-cursor {
  cursor: pointer
}

.is-flex,
.is-flex-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch
}

.is-flex-horizontal {
  flex-direction: row
}

.is-justify-end {
  justify-content: flex-end
}

.is-align-center {
  align-items: center
}

.relative__hb,
.relative__hb_hu,
.relative__hb_ro,
.relative__hb_eq {
  position: relative
}

.is-text-capitalized {
  text-transform: capitalize
}

.is-text-semi {
  font-family: Poppins, sans-serif!important
}

.is-text-regular {
  font-family: Poppins, sans-serif!important;
  font-weight: 600!important
}

.ranft {
  color: #33a1d1!important
}

.is-color-lightGrey {
  color: #bdc0c2
}

.mt-2 {
  margin-top: 10px!important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
}

.mt-4 {
  margin-top: 30px!important
}

.mb-3 {
  margin-bottom: 15px!important
}

.pt-3 {
  padding-top: 15px!important
}

.pb-5 {
  padding-bottom: 30px!important
}

.gap-10 {
  grid-gap: 10px;
  gap: 10px
}

.is-full-width {
  width: 100%!important
}

[class^=icon-] {
  height: 14px;
  width: 14px;
  display: inline-block;
  fill: currentColor
}

.pagination-left:after {
  content: "<"
}

.pagination-right:after {
  content: ">"
}

.pagination-ellipsis:after {
  content: "…"
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

@media (min-width:1280px) {
  .inventory_nft[vphawk] {
    position: relative
  }
}

.inventory_nft .inventory-assets[vphawk] {
  max-width: 1210px;
  margin: 100px auto 0
}

@media (max-width:1490px) {
  .inventory_nft .inventory-assets[vphawk] {
    max-width: 870px
  }
}

@media (max-width:940px) {
  .inventory_nft .inventory-assets[vphawk] {
    max-width: 730px
  }
}

@media (min-width:0px) and (max-width:767px) {
  .inventory_nft .inventory-assets[vphawk] {
    padding: 0 16px;
    max-width: 100%;
    margin-top: 50px
  }
}

.inventory_nft .inventory-assets .filter-options[vphawk] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px
}

@media (min-width:0px) and (max-width:1279px) {
  .inventory_nft .inventory-assets .filter-options[vphawk] {
    margin: 20px 0 0
  }
}

.sort-by-filter[vphawk] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: unset;
  left: unset;
  z-index: 2
}

.sort-by-filter .sort-by-title[vphawk] {
  height: 40px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
}

.sort-by-filter .sort-by-title p[vphawk] {
  margin-right: 10px;
  font-size: 13px;
  line-height: 16px;
  font-family: "Montserrat Regular";
  color: #a0a4a7;
  text-align: center
}

.sort-by-filter .custom-dropdown[vphawk] {
  width: 158px
}

.card .multi-button {
  z-index: 0;
  position: absolute;
  top: 2.8rem;
  left: 1.8rem;
}

.card .multi-button button {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  background: rgb(79, 70, 229);
  color: #ffffff;
  transform: translate(-50%, -50%);
  cursor: pointer;
  box-shadow: 0 0 0rem -0.25rem #5d9cec;
}

.card .multi-button button:hover {
  background: #ffffff;
  color: #46567a;
  box-shadow: 0 0 1rem -0.25rem #46567a;
}

.drop-btn[filwert],
.drop-btn_ro,
.drop-btn_hu,
.drop-btn_hule {
  font-size: 13px
}

.drop-btn[filwert],
.drop-btn_ro,
.drop-btn_hu,
.drop-btn_hule {
  width: auto;
  height: 30px;
  border-radius: 20px;
  background-color: #2f3740;
  color: #fff;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}

.drop-btn.squared[filwert],
.drop-btn_ro.squared,
.drop-btn_hu.squared,
.drop-btn_hule.squared {
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.drop-btn svg[filwert],
.drop-btn_ro svg
.drop-btn_hu svg,
.drop-btn_hule svg {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: ease
}

.inventory-astar .title[text-vd7] {
  font-size: 22px
}

.inventory-astar .assets-wrapper[text-vd7],
.inventory-astar .assets-wrapper_hu[text-vd7], 
.inventory-astar .assets-wrapper_ro[text-vd7],
.inventory-astar .assets-wrapper_eq[text-vd7] {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 254px);
  grid-gap: 34px;
  gap: 34px;
  justify-content: center
}

@media (min-width:0px) and (max-width:767px) {
  .inventory-astar .assets-wrapper[text-vd7] {
    grid-template-columns: repeat(auto-fill, 46%);
    grid-column-gap: 14px;
    -moz-column-gap: 14px;
    column-gap: 14px;
    grid-row-gap: 20px;
    row-gap: 20px
  }
}

.card[estin_] {
  position: relative;
  width: 100%;
  height: 356px;
  border-radius: 10px;
  background: linear-gradient(180deg, #2d3640 0, #29384a);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.card .card-top[estin_] {
  overflow: hidden;
  flex: 1 1 0;
}

.card .card-top figure[estin_] {
  flex: 1 1 0;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card .card-top figure .card-rocket[estin_] {
  height: 248px;
  width: 268px;
  -o-object-fit: contain;
  object-fit: contain;
}

.card .card-top figure .card-preview[estin_] {
  height: 234px;
  width: 182px;
  -o-object-fit: contain;
  object-fit: contain;
  filter: brightness(1.5);
}

.card .card-bottom[estin_] {
  padding: 15px 10px 10px;
}

.card .card-bottom .card-name[estin_] {
  font-size: 18px;
  line-height: 22px;
  font-family: Titles, sans-serif;
  color: #fff;
  text-align: left;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .card-bottom-data[estin_] {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.card .card-bottom-data .level-txth[estin_] {
  font-size: 14px;
  line-height: 18px;
  font-family: Poppins, sans-serif!important;
  color: #fff;
  text-align: center;
}

.card .card-bottom-data .card-exp[estin_] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card .card-bottom-data .gap-10[estin_] {
  grid-gap: 10px;
  gap: 10px;
}

.card .card-bottom-data .card-exp img[estin_] {
  width: 25px;
  height: 28px;
}

.card .card-bottom-data .card-exp .exp-number[estin_] {
  font-size: 16px;
  line-height: 26px;
  font-family: Poppins, sans-serif!important;
  color: #c19e5d;
  text-align: left;
  font-weight: 600!important;
}

.pagination-container[pag-vh], 
.pagination-container_hu[pag-vh], 
.pagination-container_ro[pag-vh], 
.pagination-container_eq[pag-vh] {
  width: 100%;
  height: 29px;
  margin: 20px 0;
  justify-content: center;
}

.page-item-hu,
.page-item-ro,
.page-item-eq{
  list-style-type: none;
  cursor: pointer;
}

.page-item-hu a,
.page-item-ro a,
.page-item-eq a{
  text-decoration: none;
  color: #a0a4a7;
}

.pagination-container_hu>.page-item-hu, 
.pagination-container_ro>.page-item-ro,
.pagination-container_eq>.page-item-eq {
  background-color: transparent;
  border: none;
  color: #a0a4a7;
  margin: 0 7px;
  width: 29px;
  height: 29px;
  min-width: 29px;
  min-height: 29px;
  border-radius: 28%;
  line-height: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination-container_hu>.page-item-hu:hover.active-hu,
.pagination-container_ro>.page-item-ro:hover.active-ro,
.pagination-container_eq>.page-item-eq:hover.active-eq {
  color: #fff;
  background-color: #282e36;
}

.pagination-container_hu>.page-item-hu:hover,
.pagination-container_ro>.page-item-ro:hover,
.pagination-container_eq>.page-item-eq:hover {
  background-color: #555d62;
}

.pagination-container_hu>.page-item-hu.active-hu,
.pagination-container_ro>.page-item-ro.active-ro,
.pagination-container_eq>.page-item-eq.active-eq {
  color: #fff;
  background-color: #555d62;
}

.head_ivt {
  padding: 40px 2px 10px;
}

@media (min-width: 768px) {
  .head_ivt {
    padding: 0 20px;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    height: 70px;
  }
}

.vp__tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -20px;
  padding-left: 0;
}

.vp__tabs li {
  margin-top: 15px;
  margin-right: 15px;
}

.vp__tabs li:last-child {
  margin-right: 0;
}

.vp__tabs a {
  font-family: Titles, sans-serif;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  color: #bdbdbd;
  font-size: 16px;
  background-color: #252c38;
  padding: 0 18px;
  height: 32px;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 19px 100%, 0 calc(100% - 20px));
}

.vp__tabs a:hover {
  color: #fff;
}

.vp__tabs a.active {
  color: #fff;
  background-color: #46567a;
}

@media (min-width: 768px) {
  .vp__tabs li {
    margin-top: 20px;
    margin-right: 20px;
  }
  .vp__tabs li:last-child {
    margin-right: 0;
  }
}

.content_inventory{
  display: none;
}

.content_inventory.active{
  display: block;
}

.filters_inv{
  display: none;
}

.filters_inv.active{
  display: block;
}

.contain_opro .cllasro,
.contain_ophu .cllashu,
.contain_ophule .cllashule{
  opacity: 0;
}

.icon-arrow-dropdown_ro,
.icon-arrow-dropdown_hu,
.icon-arrow-dropdown_hule{
  height: 14px;
  width: 14px;
  display: inline-block;
  fill: currentColor;
  transition: all 0.4s ease;
}

.cllasro,
.cllashu,
.cllashule {
  width: auto;
  height: 40px;
  background-color: #2f3740;
  color: #fff;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-100%);
  pointer-events: none;
  cursor: pointer;
  transition: all 0.4s ease;
}

.contain_opro.active .cllasro,
.contain_ophu.active .cllashu,
.contain_ophule.active .cllashule{
  opacity: 1;
  transform: translateY(0%);
}

.contain_opro .cllasro:last-child,
.contain_ophu .cllashu:last-child,
.contain_ophule .cllashule:last-child{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cllasro span,
.cllashu span,
.cllashule span{
  font-family: Poppins, sans-serif!important;
  font-size: 13px;
}

.icon-arrow-dropdown_ro.active,
.icon-arrow-dropdown_hu.active,
.icon-arrow-dropdown_hule.active{
  transform: rotate(180deg);
}

.cont_formeq {  
  display: grid;
  padding: 12px;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 0.4fr 0.2fr 0.4fr 0.2fr 0.4fr 0.4fr;
  grid-auto-columns: 1fr;
  gap: 9px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "equip_name"
    "name_txt"
    "rocket_sele"
    "selecte_rock"
    "human_sele"
    "selecte_huma"
    "count_equip";
}

.name_reqin{
  width: 100%;
  height: 35px;
  background-color: #4a5569;
  border-radius: 8px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  padding-left: 10px;
  font-weight: 400;
  letter-spacing: .03rem;
}

.name_reqin::placeholder{
  color: rgba(255, 255, 255, 0.2);
}

.name_reqin:focus{
  outline: none;
  padding-left: 10px;
  border: 1px solid #69a3b9;
}

multi-input {
  display: inline-block;
  border: 2px solid #4a5569;
  border-radius: 8px;
  display: block;
  overflow: hidden;
  padding: 5px;
}

.rockets_slin{
  height: 26px;
  border-radius: 5px;
  width: 140px;
  background-color: #4a5569;
}

.humans_slin{
  height: 26px;
  border-radius: 5px;
  width: 140px;
  background-color: #4a5569;
}

.equip_name { 
  grid-area: equip_name;  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.equip_name label{
  font-family: Titles, sans-serif;
  font-size: 22px;
}

.name_txt { grid-area: name_txt; }

.rocket_sele { 
  grid-area: rocket_sele; 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rocket_sele label{
  font-family: Titles, sans-serif;
  font-size: 22px;
}

.selecte_rock { grid-area: selecte_rock; }

.human_sele { 
  grid-area: human_sele;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; 
}

.human_sele label{
  font-family: Titles, sans-serif;
  font-size: 22px;
}

.selecte_huma { grid-area: selecte_huma; }

.count_equip {
  display: grid; 
  margin-top: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "coun_human count_rock coun_exp full_xd"; 
  grid-area: count_equip; 
}
.coun_human { 
  grid-area: coun_human;
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #4a5569;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.coun_human span{
  margin-left: 4px;
}

.count_rock { 
  grid-area: count_rock; 
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #4a5569;
  border-top: 2px solid #4a5569;
  border-right: 2px solid #4a5569;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.count_rock span{
  margin-left: 4px;
}

.coun_exp { 
  grid-area: coun_exp; 
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #4a5569;
  border-top: 2px solid #4a5569;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.full_xd { 
  grid-area: full_xd; 
  font-family: Poppins, sans-serif;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #4a5569;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.tab{
  font-family: Titles, sans-serif;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  font-size: 16px;
  padding: 0 18px;
  height: 32px;
  -webkit-clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 19px 100%, 0 calc(100% - 20px));
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 19px 100%, 0 calc(100% - 20px));
}
.card-robot {
  position: relative; 
    width: 100%; 
    height: 356px;
    border-radius: 10px;
    background: linear-gradient(
180deg, #2d3640 0, #29384a);
     box-shadow: 0 0 10px rgb(0 0 0 / 20%); 
    padding: 5px; 
    cursor: pointer;
     display: flex; 
     flex-direction: column; 
     justify-content: space-between; 
     align-items: stretch;
}