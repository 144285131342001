



button {
  border: none;
  background-color: transparent;
  transition: 0.5s ease;
  cursor: pointer;
}

button:focus {
  outline: none;
}

a {
  transition: 0.5s ease;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

input,
select {
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: 0.5s ease;
}

input:focus,
select:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #f5f5f5
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5
}

::-webkit-scrollbar-thumb {
  background-color: #14181d;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

::-moz-selection {
  background: #a782e9;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #a782e9;
  color: #fff;
  text-shadow: none;
}

::-webkit-input-placeholder {
  color: #dbdada;
  opacity: 1;
}

::-moz-placeholder {
  color: #dbdada;
  opacity: 1;
}

:-moz-placeholder {
  color: #dbdada;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #dbdada;
  opacity: 1;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

@media (min-width: 1310px) {
  .container {
    max-width: 1310px;
  }
}

.collapse:not(.show) {
  display: none;
}

.section {
  position: relative;
  padding-top: 60px;
}

.last-contxt {
  padding-bottom: 60px;
}

.selog_ {
  padding-top: 40px;
}

@media (min-width: 576px) {
  .section {
    padding-top: 70px;
  }
  .last-contxt {
    padding-bottom: 70px;
  }
  .selog_ {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .section {
    padding-top: 80px;
  }
  .last-contxt {
    padding-bottom: 80px;
  }
  .selog_ {
    padding-top: 50px;
  }
}

@media (min-width: 1200px) {
  .section {
    padding-top: 90px;
  }
  .last-contxt {
    padding-bottom: 90px;
  }
  .selog_ {
    padding-top: 50px;
  }
}

.paginator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.paginator__counter {
  display: none;
  font-family: Titles, sans-serif;
}

.paginator__wrap,
.paginator__wrap_hu,
.paginator__wrap_ro,
.paginator__wrap_eq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  max-width: 100%;
}

.paginator__item,
.page-item-hu,
.page-item-ro,
.page-item-eq {
  margin-right: 15px;
}

.paginator__item a,
.page-item-hu a,
.page-item-ro a,
.page-item-eq a {
  font-size: 14px;
  height: 44px;
  width: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(167, 130, 233, 0.03);
  border: 2px solid rgba(167, 130, 233, 0.06);
  border-radius: 6px;
  color: #dbdada;
  text-align: center;
  position: relative;
}

.paginator__item:last-child,
.page-item-hu:last-child
.page-item-ro:last-child
.page-item-eq:last-child {
  margin-right: 0;
}

.paginator__item:hover a,
.page-item-hu:hover a,
.page-item-ro:hover a,
.page-item-eq:hover a {
  color: #fff;
  background-color: rgba(167, 130, 233, 0.04);
  border-color: rgba(167, 130, 233, 0.07);
}

.paginator__item--prev svg,
.paginator__item--next svg {
  width: 18px;
  height: auto;
  stroke: #dbdada;
  transition: 0.5s;
}

.paginator__item--prev:hover svg,
.paginator__item--next:hover svg {
  stroke: #fff;
}

.paginator__item--active,
.page-item-hu.active-hu,
.page-item-ro.active-ro,
.page-item-eq.active-eq {
  cursor: default;
}

.paginator__item--active a,
.page-item-hu.active-hu a, 
.page-item-ro.active-ro a,
.page-item-eq.active-eq a{
  color: #fff;
  cursor: default;
  border-color: #67759f;
}

.paginator__item--active:hover a,
.page-item-hu.active-hu:hover a,
.page-item-ro.active-ro:hover a,
.page-item-eq.active-eq:hover a {
  background-color: rgba(167, 130, 233, 0.04);
  border-color: #67759f;
  color: #fff;
}

@media (min-width: 576px) {
  .paginator {
    margin-top: 50px;
    justify-content: space-between;
  }
  .paginator__counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(167, 130, 233, 0.03);
    border: 1px solid rgba(167, 130, 233, 0.06);
    height: 44px;
    padding: 0 20px;
    border-radius: 6px;
    font-size: 14px;
    color: #dbdada;
  }
  .paginator__item {
    margin-right: 20px;
  }
  .paginator__item:last-child {
    margin-right: 0;
  }
}

.card {
  position: relative;
  display: block;
  margin-top: 30px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #1b222e;
}

.card__cover {
  position: relative;
  display: block;
}

.card__cover img {
  width: 100%;
  position: relative;
  z-index: 1;
  transition: 0.5s;
}

.card__cover:hover img {
  opacity: 0.6;
}

.card__b1 {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  top: 20px;
  left: 15px;
  z-index: 2;
  pointer-events: none;
}

.card__b1 li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}

.card__b1 li svg {
  fill: #fff;
  width: 14px;
  height: auto;
}

.card__b1 li.ps {
  background-color: #665cbe;
}

.card__b1 li.xb {
  background-color: #0e7a0d;
}

.card__b1 li:last-child {
  margin-right: 0;
}

.card__title {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  border-left: 1px solid rgba(167, 130, 233, 0.06);
  border-right: 1px solid rgba(167, 130, 233, 0.06);
  width: 100%;
}

.card__title h3 {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  font-family: Titles, sans-serif;
  margin-bottom: 15px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  transition: 0.5s;
}

.card__title h3 a {
  color: #fff;
}

.card__title h3 a:hover {
  color: #707fad;
}

.card__title h3:hover {
  color: #707fad;
}

.card__title span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 100%;
}

.card__title span s {
  font-size: 12px;
  color: #c14343;
  margin-left: 10px;
  font-weight: 600;
  line-height: 100%;
}

.card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 15px;
  width: 100%;
  border-left: 1px solid rgba(167, 130, 233, 0.06);
  border-right: 1px solid rgba(167, 130, 233, 0.06);
  border-bottom: 1px solid rgba(167, 130, 233, 0.06);
  border-radius: 0 0 6px 6px;
}

.card__buy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 59px);
  height: 44px;
  border-radius: 6px;
  background-color: #29b474;
  color: #054031;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.card__buy:hover {
  background-color: #67759f;
  color: #fff;
}

.card__favorite {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 44px;
  color: #828f99;
  font-weight: 600;
  background-color: rgba(167, 130, 233, 0.03);
  border: 1px solid rgba(167, 130, 233, 0.06);
  border-radius: 6px;
}

.card__favorite svg {
  stroke: #a782e9;
  width: 22px;
  height: auto;
  transition: 0.5s;
}

.card__favorite:hover {
  background-color: rgba(167, 130, 233, 0.04);
  border-color: rgba(167, 130, 233, 0.07);
}

.card__favorite:hover svg {
  stroke: #fd6060;
}

.card__it1 {
  position: absolute;
  bottom: 20px;
  left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #383a4c;
  border-radius: 6px;
  height: 24px;
  padding: 0 13px;
  font-family: Poppins, sans-serif;
  font-size: 12.5px;
  font-weight: 600;
  z-index: 2;
  pointer-events: none;
}

.card__it2 {
  position: absolute;
  bottom: 20px;
  left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #a58343;
  border-radius: 6px;
  height: 24px;
  padding: 0 13px;
  font-family: Poppins, sans-serif;
  font-size: 12.5px;
  font-weight: 600;
  z-index: 2;
  pointer-events: none;
}

@media (min-width: 360px) {
  .card-vpad {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-right: 1px solid rgba(167, 130, 233, 0.06);
  }
  .card-vpad .card__cover {
    width: 165px;
  }
  .card-vpad .card__title {
    width: calc(100% - 165px);
    padding: 15px;
    border-left: none;
    border-right: none;
    border-top: 1px solid rgba(167, 130, 233, 0.06);
  }
  .card-vpad .card__title h3 {
    white-space: normal;
  }
  .card-vpad .card__actions {
    position: absolute;
    width: calc(100% - 165px);
    bottom: 0;
    right: 0;
    padding: 0 15px 15px;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  .card-vpad .card__b1 {
    top: 165px;
    left: 15px;
  }
  .card-vpad .card__it1,
  .card-vpad .card__it2 {
    bottom: 15px;
    left: 15px;
  }
  .card__title {
    padding: 20px;
  }
  .card__actions {
    padding: 0 20px 20px;
  }
  .card__b1 {
    left: 20px;
  }
  .card__it1,
  .card__it2 {
    left: 20px;
  }
  .card__buy {
    width: calc(100% - 98px);
  }
}

@media (min-width: 576px) {
  .card-vpad {
    flex-direction: column;
    border-right: none;
  }
  .card-vpad .card__cover {
    width: 100%;
  }
  .card-vpad .card__title {
    width: 100%;
    padding: 20px;
    border-left: 1px solid rgba(167, 130, 233, 0.06);
    border-right: 1px solid rgba(167, 130, 233, 0.06);
    border-top: none;
  }
  .card-vpad .card__title h3 {
    white-space: nowrap;
  }
  .card-vpad .card__actions {
    position: relative;
    bottom: auto;
    right: auto;
    width: 100%;
    padding: 0 20px 20px;
    border-left: 1px solid rgba(167, 130, 233, 0.06);
    border-right: 1px solid rgba(167, 130, 233, 0.06);
    border-radius: 0 0 6px 6px;
  }
  .card-vpad .card__b1 {
    top: 262px;
    left: 20px;
  }
  .card-vpad .card__it1,
  .card-vpad .card__it2 {
    bottom: 20px;
    left: 20px;
  }
}

@media (min-width: 768px){
  .card-vpad .card__b1 {
    top: 222px;
    left: 20px;
  }
}

@media (min-width: 992px) {
  .dsan_ .col-lg-20 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dsan_ .col-lg-80 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 1200px) {
  .dsan_ .col-lg-20 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dsan_ .col-lg-80 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .card-vpad .card__b1 {
    top: 262px;
    left: 20px;
  }
}

.filter-wrap__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: rgba(167, 130, 233, 0.03);
  border: 2px solid rgba(130, 130, 233, 0.4);
  border-radius: 6px;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.filter-wrap__btn:hover {
  background-color: rgba(167, 130, 233, 0.04);
  border-color: rgba(130, 130, 233, 0.5);
}

@media (min-width: 576px) {
  .filter-wrap__btn {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .filter-wrap__btn {
    display: none;
  }
  .filter-wrap__content {
    display: block !important;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 20px;
}

.filter__title {
  font-family: Titles, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  color: #dfdfdf;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  letter-spacing: 0.4px;
}

.filter__title button {
  font-size: 14px;
  font-weight: 600;
  color: #67759f;
  letter-spacing: 0;
}

.filter__title button:hover {
  color: #4395c1;
}

.filter__group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
}

.filter__group:last-child {
  margin-bottom: 0;
}

.filter__label {
  font-family: Titles, sans-serif;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.4px;
  color: #dbdada;
  font-weight: normal;
  margin-bottom: 15px;
}

.filter__select-wrap {
  position: relative;
  width: 100%;
}

.filter__select {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: 2px solid rgba(167, 130, 233, 0.06);
  font-size: 14px;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  background: url("../../svg/arrow2.svg") no-repeat center right 20px rgba(167, 130, 233, 0.03);
  background-size: 12px auto;
  letter-spacing: 0.4px;
}

.filter__select option {
  padding: 0;
  margin: 0;
  color: #000;
}

.filter__select:focus {
  background-color: rgba(167, 130, 233, 0.04);
  border-color: rgba(130, 130, 233, 0.5);
}

.filter__checkboxes {
  position: relative;
  margin-top: 5px;
}

.filter__checkboxes li {
  position: relative;
  margin-bottom: 15px;
}

.filter__checkboxes li:last-child {
  margin-bottom: 0;
}

.filter__checkboxes input:not(:checked),
.filter__checkboxes input:checked {
  position: absolute;
  left: -9999px;
}

.filter__checkboxes input:not(:checked)+label,
.filter__checkboxes input:checked+label {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin: 0;
}

.filter__checkboxes input:not(:checked)+label a {
  color: #a782e9;
}

.filter__checkboxes input:not(:checked)+label a:hover {
  color: #a782e9;
}

.filter__checkboxes input:not(:checked)+label:before,
.filter__checkboxes input:checked+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(167, 130, 233, 0.03);
  border: 1px solid rgba(167, 130, 233, 0.06);
  border-radius: 6px;
}

.filter__checkboxes input:not(:checked)+label:after,
.filter__checkboxes input:checked+label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  transition: 0.5s;
}

.filter__checkboxes input:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.filter__checkboxes input:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.filter__checkboxes label::-moz-selection {
  background: transparent;
  color: #fff;
}

.filter__checkboxes label::selection {
  background: transparent;
  color: #fff;
}

.filter__btn {
  font-family: Titles, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: rgba(167, 130, 233, 0.03);
  border: 2px solid rgba(130, 130, 233, 0.4);
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.filter__btn:hover {
  background-color: rgba(167, 130, 233, 0.04);
  border-color: rgba(130, 130, 233, 0.5);
}

@media (min-width: 576px) {
  .filter {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .filter {
    margin-top: 30px;
  }
}

@media (min-width: 1310px) {
  .filter {
    padding-right: 10px;
  }
}

.sign__group--checkbox input:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sign__group--checkbox input:not(:checked)+label {
  font-size: 14px;
  color: #dbdada;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 20px;
  margin: 0;
}

.sign__group--checkbox input:not(:checked)+label a {
  color: #a782e9;
}

.sign__group--checkbox input:not(:checked)+label a:hover {
  color: #a782e9;
}

.sign__group--checkbox input:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(167, 130, 233, 0.03);
  border: 1px solid rgba(167, 130, 233, 0.06);
  border-radius: 6px;
}

.sign__group--checkbox input:not(:checked)+label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  transition: 0.5s;

}

.sign__group--checkbox input:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1360px
  }
}

.row,
.row_hu,
.row_ro,
.row_eq {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 8px;
}

.col-12,
.col-12_hu,
.col-12_ro,
.col-12_eq,
.col-md-4,
.col-sm-6,
.col-xl-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col-12,
.col-12_hu,
.col-12_ro,
.col-12_eq {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

@media (min-width:576px) {
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media (min-width:768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }
}

@media (min-width:1200px) {
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-moz-selection {
  background: #fff;
  color: #222227;
  text-shadow: none;
}

::selection {
  background: #fff;
  color: #222227;
  text-shadow: none;
}

::-webkit-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

::-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

:focus-visible {
  outline: 0px dotted;
}

.main__filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 34px;
  padding: 20px 20px 10px;
  background-color: #2f3740;
  position: relative;
  border-radius: 22px;
}

@media (min-width: 768px) {
  .main__filter {
    padding: 0 20px;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    height: 70px;
  }
}

.sign__radio input:not(:checked) {
  position: absolute;
  left: -9999px;
}

.main__tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -24px;
}

.main__tabs li {
  margin-top: 15px;
  margin-right: 15px;
}

.main__tabs li:last-child {
  margin-right: 0;
}

.main__tabs a {
  font-family: Titles, sans-serif;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  color: #bdbdbd;
  font-size: 16px;
  background-color: #252c38;
  border-radius: 12px;
  padding: 0 15px;
  height: 32px;
}

.main__tabs a:hover {
  color: #fff;
}

.main__tabs a.active {
  color: #fff;
  background-color: #46567a;
}

@media (min-width: 768px) {
  .main__tabs li {
    margin-top: 20px;
    margin-right: 20px;
  }
  .main__tabs li:last-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {.txt__markd {
  font-size: 32px;
}
}

@media (min-width: 576px) {.txt__markd {
  font-size: 30px;
}}


.txt__markd {
  color: #b4b9cf;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  padding-left: 10px;
}

@media (min-width: 768px){ .txt__markd:before {
  top: 3px;
  bottom: 3px;
}}


.txt__markd:before {
  content: '';
  position: absolute;
  display: block;
  top: 2px;
  bottom: 2px;
  left: 0;
  width: 3px;
  background-color: #67759f;
  border-radius: 4px;
}

.content_market{
  display: none;
}

.content_market.active{
  display: block;
}

.filter_tab{
  display: none;
}

.filter_tab.active{
  display: block;
}