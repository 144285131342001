

html {

}

body {

}

p {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

h1 {
  font-family: Titles, sans-serif;
  margin-top: 0;
  margin-bottom: .5rem
}

ul.b {
  list-style-type: square;
  color: #bdccdd;
}

h4 {
  font-family: Titles, sans-serif;
}

img {
  vertical-align: middle;
  border-style: none
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

.para {
  font-size: 16px;
}

@media(min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media(min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media(min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media(min-width:1200px) {
  .container {
    max-width: 1140px
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.col,
.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

@media print {
  *,
   :after,
   :before {
    text-shadow: none!important;
    box-shadow: none!important
  }
  img {
    page-break-inside: avoid
  }
  p {
    orphans: 3;
    widows: 3
  }
  @page {
    size: a3
  }
  .container {
    min-width: 992px!important
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

img {
  vertical-align: middle;
  border-style: none
}

h1 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}

h1 {
  font-size: 2.5rem
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media(min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media(min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media(min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media(min-width:1200px) {
  .container {
    max-width: 1140px
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.col,
.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

@supports(position:sticky) {}

@media print {
  *,
   :after,
   :before {
    text-shadow: none!important;
    box-shadow: none!important
  }
  img {
    page-break-inside: avoid
  }
  @page {
    size: a3
  }
  body {
    min-width: 992px!important
  }
  .container {
    min-width: 992px!important
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media(min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media(min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media(min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media(min-width:1200px) {
  .container {
    max-width: 1140px
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.col,
.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

.tlt {
  font-family: Titles, sans-serif
}

.home-section {
  margin-top: 80px;
  margin-bottom: 80px
}

.home-section .section-tlt {
  text-align: center;
  color: #fff;
  position: relative;
  padding-top: 30px
}

.home-section .section-tlt .tlt {
  text-transform: uppercase
}

.boxs-ls {
  margin-bottom: 30px;
  justify-content: center
}

button {
  font-family: inherit;
  font-size: 100%;
  margin: 0
}

img {
  height: auto;
  max-width: 100%
}

.z-10 {
  z-index: 10
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-1 {
  margin-bottom: .25rem;
  margin-top: .25rem
}

.my-10 {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem
}

.mt-3 {
  margin-top: .75rem
}

.ml-2 {
  margin-left: .5rem
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.h-80 {
  height: 20rem
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.rounded-2xl {
  border-radius: 1rem
}

.bg-color1 {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 32, 42, var(--tw-bg-opacity))
}

.bg-color2 {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 38, 49, var(--tw-bg-opacity))
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem
}

.pt-10 {
  padding-top: 0.5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-colorGreen {
  --tw-text-opacity: 1;
  color: rgba(100, 183, 111, var(--tw-text-opacity))
}

.shadow-lg {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05)
}

@media (min-width:768px) {
  .md\:w-1\/2 {
    width: 30%
  }
}

.bn632-hover {
  width: 90px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  height: 38px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn25 {
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
}

.prb {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);
}

@media (min-width: 992px) {
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.justify-content-center {
  justify-content: center!important;
}

.p-sidebar-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.mt-4 {
  margin-top: 1.5rem!important;
}

.d-flex {
  display: flex!important;
}

@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0!important;
  }
}

.mb-3 {
  margin-bottom: 1rem!important;
}

@media only screen and (max-width:720px) and (min-width:220px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 86%;
  }
}

@media (min-width: 720px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 40%;
  }
}

.p-select-card {
  cursor: pointer;
  padding: 8px 40px;
  border-radius: 20px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .12);
  background-color: #1b2026;
}

.p-select-card-title {
  color: #828f99;
  font-size: 20px;
  text-align: center;
}

.p-select-card-description {
  font-size: 15px;
  min-height: 3rem;
}

@media (min-width: 360px) {
  .card__it1,
  .card__it2 {
    left: 20px;
  }
}

.card__it2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #414862;
  border-radius: 6px;
  height: 24px;
  padding: 0 13px;
  font-family: Poppins, sans-serif;
  font-size: 12.5px;
  font-weight: 600;
}