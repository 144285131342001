

body,
html {
  height: 100%
}

body {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px
}

a {
  color: #000;
  transition: color .2s ease, opacity .2s ease, border-color .2s ease
}

a:hover {
  color: #8d8e8d;
  cursor: pointer
}

h1 {
  font-family: Titles, sans-serif;
  font-size: 18px
}

h1 .rocket-icon {
  max-height: 40px
}

h1 {
  display: flex;
  align-items: center;
  transition: font-size .2s ease;
  text-align: left
}

@media(min-width:576px) {
  h1 {
    font-size: 18px
  }
}

@media(min-width:768px) {
  h1 {
    font-size: 26px
  }
}

@media(min-width:992px) {
  h1 {
    font-size: 30px
  }
}

*,
:after,
:before {
  box-sizing: border-box
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

img {
  vertical-align: middle
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #f5f5f5
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5
}

::-webkit-scrollbar-thumb {
  background-color: #14181d;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y)*-1);
  margin-right: calc(var(--bs-gutter-x)*-.5);
  margin-left: calc(var(--bs-gutter-x)*-.5)
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)*.5);
  padding-left: calc(var(--bs-gutter-x)*.5);
  margin-top: var(--bs-gutter-y)
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

@media(min-width:992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  -webkit-margin-end: .75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
  .form-control::file-selector-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3
}

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  -webkit-margin-end: .5rem;
  margin-inline-end: .5rem
}

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0, 0, 0, 0)!important;
  white-space: nowrap!important;
  border: 0!important
}

.me-2 {
  margin-right: .5rem!important
}

.me-3 {
  margin-right: 1rem!important
}

.mb-4 {
  margin-bottom: 1.5rem!important
}

@-webkit-keyframes slideRightLong {
  0% {
    transform: translate(-10px)
  }
  to {
    transform: translate(0)
  }
}

@keyframes slideRightLong {
  0% {
    transform: translate(-10px)
  }
  to {
    transform: translate(0)
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: visible
  }
  to {
    opacity: 1;
    visibility: visible
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: visible
  }
  to {
    opacity: 1;
    visibility: visible
  }
}

.pagination .page-link:focus:not(:hover) {
  color: #40413b
}

.form-switch .form-check-input.on-off:not(:checked) {
  border-color: #d6c5b5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvgxmlns='http://www.w3.org/2000/svg'viewBox='-4-488'%3E%3Ccircler='3'fill='%237e7676'/%3E%3C/svg%3E")
}

.hakkv_sx {
  box-shadow: 0 0 20px rgba(0, 0, 0, .02);
  border-radius: .3rem;
  padding: 5.5rem;
  transition: padding .2s ease;
  -webkit-animation: slideRightLong .4s ease forwards, fadeIn .4s ease forwards;
  animation: slideRightLong .4s ease forwards, fadeIn .4s ease forwards
}

@media(min-width:460px) {
  .hakkv_sx {
    padding-top: 6rem
  }
}

.exp-badge {
  display: inline-flex;
  padding: .8rem 1.4rem;
  border-radius: .3rem;
  margin: 0 1rem 1rem 0;
  align-items: center;
  cursor: default
}

.exp-badge p {
  margin: 0;
  font-size: 1rem
}

.exp-badge p:first-of-type {
  font-weight: 700
}

.exp-badge p:first-of-type:after {
  content: "";
  border: 1px solid rgba(0, 0, 0, .2);
  margin: 0 .6rem
}

.nfts-list>.col .nft-block.selectable.selected .nft-block-layer:not(:hover) {
  background: rgba(131, 115, 106, .1);
  opacity: 1;
  visibility: visible
}

.spx_data {
  border-radius: .3rem;
  display: flex;
  padding: 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem
}

.spx_data .spx-icon {
  margin-right: 1.5rem;
  display: flex;
  align-items: center
}

.spx_data .spx-icon span {
  border-radius: .3rem;
  font-size: 1.6em;
  color: #fff;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center
}

.spx_data .spx-icon .icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  margin-right: 10px
}

.spx_data .spx-content {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.spx_data .spx-content p {
  margin: 0;
  font-weight: 700
}

.spx_data .spx-content p.spx-title {
  font-size: 1.1em;
  opacity: .6;
  white-space: nowrap
}

.spx_data .spx-content p.spx-value {
  font-size: 1.4em
}

.spx_data .spx-content p.spx-description {
  opacity: .6
}

@media(min-width:576px) {
  .spx_data {
    width: auto
  }
}

.exp-info {
  padding: 1rem;
  background: rgba(0, 0, 0, .07);
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem
}

.exp-info .exp-badge {
  margin: 0;
  padding: 0
}

.exp-info .exp-badge:last-of-type {
  right: 1rem
}

@media(min-width:400px) {
  .exp-info .exp-badge:last-of-type {
    position: absolute
  }
}

@media(min-width:768px) {
  .exp-info .exp-badge:last-of-type {
    position: static
  }
}

@media(min-width:992px) {
  .exp-info .exp-badge p:first-of-type:after {
    margin: 0 .3rem
  }
  .exp-info .exp-badge:last-of-type {
    position: absolute
  }
}

@media(min-width:1200px) {
  .exp-info .exp-badge p:first-of-type:after {
    margin: 0 .6rem
  }
}

.stinplat {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(306px, 1fr));
  gap: 2rem;
  margin: 2rem 2vw;
  padding: 0;
  list-style-type: none
}

.vplanet {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none
}

.card__image {
  width: 100%;
  height: auto
}

.vp__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: #12071f;
  transform: translateY(100%);
  transition: .2s ease-in-out
}

.vplanet:hover .vp__overlay {
  transform: translateY(0)
}

.vp__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  background-image: radial-gradient(ellipse at center,var(--tw-gradient-stops));
  --tw-gradient-from: #39004d var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(57,0,77,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #000 var(--tw-gradient-to-position);
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  transform: translateY(-100%);
  transition: .2s ease-in-out
}

.vp__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1
}

.vp__arc path {
  fill: rgb(19, 32, 53);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z")
}

.vplanet:hover .vp__header {
  transform: translateY(0)
}

.vp__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px
}

.vp__title {
  font-family: Titles, sans-serif;
  font-size: 24px;
  margin: 0 0 .3em;
  color: #ffff
}

.vp__wrate {
  font-size: 13.5px;
  color: #bdd7cc;
  font-weight: 600
}

span:nth-child(2) {
  width: 20px;
  margin-left: 0;
  top: 12%
}

:root {
  --surface-color: #1d2228;
  --curve: 30
}

.start-sc {
  position: absolute;
  padding: .5rem;
  right: 16px;
  z-index: 2
}

.st-badge.st-light {
  background-color: #f3f3f4;
  color: #171d26;
  border-color: #f3f3f4
}

.st-badge {
  grid-gap: .25rem;
  gap: .25rem
}

.st-badge {
  padding: .1875rem .375rem;
  border-radius: .25rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  border: .0625rem solid;
  white-space: nowrap
}

.st-badge {
  font-size: .75rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-weight: 600
}

.nump_tag {
  position: absolute;
  padding: .5rem;
  z-index: 2
}

.pl-badge.tag_number {
  color: #d1d1d2
}

.pl-badge {
  font-family: Titles, sans-serif;
  font-size: 36px;
  padding: .1875rem .375rem
}

.img_succes_xp {
  width: 100%;
  max-height: 200px;
  display: flex;
  justify-content: center
}

.img_succes_xp img {
  height: 200px
}

.cont_succes {
  display: grid;
  padding: 12px;
  grid-template-columns: .8fr .8fr;
  grid-template-rows: .5fr .7fr .5fr .5fr .7fr;
  gap: 20px 13px;
  grid-auto-flow: row;
  grid-template-areas: "Succes Succes" "crypt crypt" "roll need_b" "experie fleet_lev" "txt_hash txt_hash"
}

.Succes {
  grid-area: Succes
}

.Succes .succ_sp {
  background-color: #4395c1;
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 15px
}

.Succes .succ_sp img {
  height: 26px;
  margin-right: 10px
}

.Succes .succ_sp p {
  margin: 0;
  color: #2f3842
}

.Lose {
  grid-area: Succes
}

.Lose .lose_sp {
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 15px
}

.Lose .lose_sp img {
  height: 26px;
  margin-right: 10px
}

.Lose .lose_sp p {
  margin: 0;
  color: #2f3842
}

.crypt {
  grid-area: crypt;
  margin-top: 5px;
  margin-bottom: 5px
}

.crypt .crypt_cou {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row
}

.crypt .crypt_cou img {
  height: 40px;
  margin-right: 15px
}

.crypt .crypt_cou p {
  font-family: Titles, sans-serif;
  margin: 0;
  font-size: 35px
}

.roll {
  grid-area: roll
}

.need_b {
  grid-area: need_b
}

.experie {
  grid-area: experie
}

.fleet_lev {
  grid-area: fleet_lev
}

.txt_hash {
  grid-area: txt_hash
}

.roll .roll_sp,
.need_b .need_sp,
.experie .exp_sp,
.fleet_lev .flet_sp {
  background-color: #1f2936;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 8px
}

.roll .roll_sp img,
.need_b .need_sp img,
.experie .exp_sp img,
.fleet_lev .flet_sp img {
  height: 24px;
  margin-right: 10px;
}

.roll .roll_sp p,
.need_b .need_sp p,
.experie .exp_sp p,
.fleet_lev .flet_sp p {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400
}

.txt_hash .txt_hsp {
  background-color: #1f2936;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px
}

.txt_hash .txt_hsp img {
  padding-top: 10px;
  height: 28px;
  margin-right: 10px;
}

.txt_hash .txt_hsp p {
  padding-top: 10px;
  margin: 0;
  color: #fff;
  font-size: 11px;
  font-weight: 400
}

.txt_hash .txt_hsp p a {
  color: #fff
}

.txt_hash .txt_hsp p a:hover {
  color: #4395c1
}

.txt_hash .help_txha {
  background-color: #1f2936;
  margin: 0;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  padding-bottom: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px
}

@media(max-width:620px) {
  .hakkv_sx {
    padding: 2.5rem;
    padding-top: 5.5rem
  }
  .spx_data .spx-icon {
    margin-right: .7rem
  }
  h1 {
    justify-content: center
  }
  .spx_data .spx-content p.spx-title {
    font-size: 1em
  }
  .spx_data .spx-content p.spx-description {
    font-size: 1em
  }
  .vp__thumb {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-left: -12px
  }
  .vp__header-text {
    margin-left: -20px
  }
  .vp__title {
    font-size: 22px
  }
}

@media(max-width:485px) {
  .exp-badge p {
    font-size: .85rem
  }
}