@tailwind base;
@tailwind components;
@tailwind utilities;


.text-shadow {
  text-shadow: 0 2px 4px rgb(0 0 0 / 80%);
    color: #fff;
    font-weight: 700;
    line-height: 44px;
    margin-top: 0;
}


body {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-image: radial-gradient(ellipse at center,var(--tw-gradient-stops));
  --tw-gradient-from: #39004d var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(57,0,77,0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}


.text-hero-gradient {
  --tw-gradient-from: #bb28cf;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to,rgba(187,40,207,0));
  --tw-gradient-to: #9c34fc;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: transparent;
}

.card-rocket-1:after {
  box-shadow: inset 0 0 40px rgb(0 102 204 / 60%), inset 0 0 160px rgb(0 102 204 / 40%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}



.card-rocket-2:after {
  box-shadow: inset 0 0 40px rgb(35 96 37 / 60%), inset 0 0 160px rgb(35 96 24 / 40%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.card-rocket-3:after {
  box-shadow: inset 0 0 40px rgb(245 216 140 / 60%), inset 0 0 160px rgb(206 161 42 / 40%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}



.card-rocket-4:after {
  box-shadow: inset 0 0 40px rgb(67 28 34 / 60%), inset 0 0 160px rgb(202 65 85 / 40%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.card-rocket-5:after {
  box-shadow: inset 0 0 40px rgb(67 30 115 / 60%), inset 0 0 160px rgb(121 11 139 / 40%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}


.navigationButtons {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 40px;
}
 
.navigationButtons a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  color: #a0a4a7;
  cursor: pointer;
}
 
.navigationButtons a:hover {
  color: white;
}
 
.navigationActive a {
  color: #fff;
  background-color: #555d62;
}
 
.navigationDisabled a {
  color: #fff;
  background-color: grey;
}

.video:hover::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:ring-primary-500:disabled {
  --tw-ring-opacity: 1;
  --tw-ring-color: none;
}

.hover\:ring-offset-2:hover {
  --tw-ring-offset-width: 2px;
}
.hover\:ring-primary-500:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(27, 32, 38, var(--tw-ring-opacity));
}

.hover\:ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.hover\:outline-none:hover {
  outline: 2px solid transparent;
  outline-offset: 2px;
}


.bg-purple-grandient {
  background-image: radial-gradient(circle,#39004d 0,#000 139%);
}


:root {
  --primary-color: #14181d;
  --text-color: #828f99;
  --heading-color: #1e3547;
  --light-bg: rgba(30, 165, 154, 0.25);
  --btn-color: #1ea59a
}

:root {
  --header-height: 4rem;
  --mb: 4rem
}

button {
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-image: radial-gradient(circle,#39004d 0,#000 139%);
  margin-bottom: var(--mb)
}

.estin {
  max-width: 1200px;
  width: calc(100% - 2rem);
  margin: 0 .5rem
}

.logo {
  display: flex;
  align-items: center
}

.logo img {
  margin-right: 10px;
  width: 45px;
  height: 45px;
  object-fit: cover
}

.logo a {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  --tw-gradient-from: #bb28cf;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to,rgba(187,40,207,0));
  --tw-gradient-to: #9c34fc;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: transparent;
  font-family: Titles, sans-serif
}

.navbar {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.register_btn {
  height: 35px;
  background-color: #3F008E;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: .1px;
  border-radius: 7px;
  transition: .5s;
  font-family: Titles, sans-serif;
}

.register_btn:hover {
  --light-bg: rgba(53, 231, 216, 0.25)
}

.register_btn:active {
  transform: scale(.6)
}

.close_btn,
.toggle_btn {
  width: fit-content;
  height: 25px
}

.close_btn i,
.toggle_btn i {
  font-size: 19px
}

.close_btn {
  position: absolute;
  top: .75rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer
}

@media screen and (max-width:768px) {
  .nav_menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    padding: 3.5rem 1.5rem 0;
    background: #14181d;
    transition: .5s
  }
}

.nav_menu {
  display: flex
}

.nav_menu_item {
  margin-bottom: 2rem
}

.nav_menu .nav_menu_item a {
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: .7px;
  font-weight: 500;
  color: #fff;
  font-family: Titles, sans-serif
}

.nav_menu_item_link.active {
  color: #a45de9!important;
  font-weight: 500
}

.show {
  right: 0
}

.fa-bars,
.fa-times {
  color: #fff
}

@media screen and (min-width:769px) {
  body {
    margin: 0
  }
  .navbar {
    height: var(--header-height)
  }
  .close_btn,
  .toggle_btn {
    display: none
  }
  .nav_menu_list {
    display: flex;
    align-items: center;
    justify-content: center
  }
  .nav_menu_item {
    margin-left: 3rem;
    margin-top: 2rem
  }
  .hero_content {
    display: flex;
    flex-direction: row
  }
  .hero_about_wrapper,
  .hero_img_wrapper {
    width: 50%;
    height: 100%
  }
  .hero_about_wrapper {
    order: 1
  }
  .hero_img_wrapper {
    order: 2
  }
  .hero_img img {
    width: 500px
  }
  .hero_about .tagline {
    margin-top: 1.5rem;
    width: 75%;
    text-align: left
  }
  .tagline p {
    font-size: 18px;
    line-height: 1.5;
    color: #828f99
  }
  .btn_wrapper {
    justify-content: start
  }
  .btn_contact {
    width: 150px;
    height: 40px;
    font-size: 17px
  }
  .btn_watch {
    font-size: 16px
  }
}

@media screen and (min-width:1200px) {
  .estin {
    margin: 0 auto
  }
}

@media screen and (min-width:200px) {
  .navbar {
    height: var(--header-height)
  }
}

.icob47 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-margin-start: .5rem;
  margin-inline-start: .5rem
}

.img-badh {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  line-height: 1em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: currentColor;
  vertical-align: middle
}

.YouTubePopUp-Wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 9999999999999
}

.YouTubePopUp-animation {
  opacity: 0;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: YouTubePopUp;
  animation-name: YouTubePopUp
}

@-webkit-keyframes YouTubePopUp {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes YouTubePopUp {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

body.logged-in .YouTubePopUp-Wrap {
  top: 32px;
  z-index: 99998
}

.YouTubePopUp-Content {
  max-width: 860px;
  display: block;
  margin: 0 auto;
  height: 100%;
  position: relative
}

.YouTubePopUp-Content iframe {
  max-width: 100%!important;
  width: 100%!important;
  display: block!important;
  height: 480px!important;
  border: none!important;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0
}

.YouTubePopUp-Hide {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: YouTubePopUpHide;
  animation-name: YouTubePopUpHide
}

@-webkit-keyframes YouTubePopUpHide {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes YouTubePopUpHide {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.YouTubePopUp-Close {
  position: absolute;
  top: 0;
  cursor: pointer;
  bottom: 528px;
  right: 0;
  margin: auto 0;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px 24px;
  -o-background-size: 24px 24px
}

.YouTubePopUp-Close:hover {
  opacity: .5
}

@media all and (max-width:768px) and (min-width:10px) {
  .YouTubePopUp-Content {
    max-width: 90%
  }
}

@media all and (max-width:600px) and (min-width:10px) {
  .YouTubePopUp-Content iframe {
    height: 320px!important
  }
  .YouTubePopUp-Close {
    bottom: 362px
  }
}

@media all and (max-width:480px) and (min-width:10px) {
  .YouTubePopUp-Content iframe {
    height: 220px!important
  }
  .YouTubePopUp-Close {
    bottom: 262px
  }
}


@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap);
a {
  text-decoration: none
}

a:hover {
  text-decoration: none;
  cursor: pointer
}

.primary-button {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  border: none;
  outline: 0;
  width: 176px;
  height: 54px;
  line-height: 54px;
  background-color: #fff;
  color: #2c4f68;
  -webkit-appearance: none;
  border-radius: 2px;
  transition: .2s
}

.primary-button:hover {
  background-color: #c99666;
  color: #fff;
  cursor: pointer
}

#levp_z {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Titles;
  font-size: 24px;
  text-transform: uppercase;
  outline: 0;
  width: 210px;
  height: 48px;
  background-image: linear-gradient(70deg, #a79a8a, #838687);
  color: #fff;
  cursor: auto;
  -webkit-appearance: none;
  border-radius: 4px;
  transition: .2s
}

#levp_z:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid #606060;
  padding: 0 20px
}

@font-face {
  font-family: Titles;
  src: url(./fonts/troika.ttf)
}

* {
  box-sizing: border-box
}



.main-container {
  max-width: 1500px
}

.title {
  font-family: Titles
}

#hakkv {
  overflow-x: hidden
}

#hakkv .section {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  float: left
}

#hakkv #luzpm {
  position: relative;
  width: 100%;
  height: calc(80vh - 50px);
  margin-top: 60px;
  background-color: #0c161c
}

#hakkv #luzpm #backgr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 0px)
}

#hakkv #luzpm #backgr {
  background-repeat: no-repeat;
  background-size: cover
}

#hakkv #luzpm #gp-cont {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 100px
}

#hakkv #luzpm #gp-cont .logo {
  position: relative;
  background-size: contain;
  width: 340px;
  height: 124px;
  margin-bottom: 26px
}

#hakkv #phase1 {
  height: 580px;
  overflow: hidden;
  background: linear-gradient(164deg, rgb(20 24 29) 0, rgb(28 28 28) 35%)
}

#hakkv #phase1>.container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center
}

#hakkv #phase1 .previd {
  position: relative;
  flex-shrink: 0;
  left: 60px
}

#hakkv #phase1 .previd .shabox {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  height: 300px;
  border-radius: 30px;
  box-shadow: 0 56px 32px rgba(0, 0, 0, .4), 0 94px 158px rgba(0, 0, 0, .46)
}

#hakkv #phase1 .previd .thumbnail {
  position: relative;
  background-size: contain;
  width: 720px;
  height: 407px
}

#hakkv #phase1 .previd .thumbnail:hover .play-but {
  transform: scale(1.1)
}

#hakkv #phase1 .previd .thumbnail .payimg {

  background-size: contain;
  width: inherit;
  height: inherit;
  cursor: pointer
}

#hakkv #phase1 .previd .thumbnail .play-but {
  position: absolute;
  background-size: contain;
  width: 114.6666666667px;
  height: 114.6666666667px;
  top: calc(50% - (114.6666666667px / 2));
  left: calc(50% - (114.6666666667px / 2));
  pointer-events: none;
  transition: transform .25s;
  transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

#hakkv #phase1 .right-pe {
  position: relative;
  height: 407px;
  display: flex;
  flex-direction: column;
  margin-left: 64px
}

#hakkv #phase1 .right-pe .title {
  color: #c99666;
  font-family: Titles;
  font-size: 62px;
  text-align: left;
  text-transform: uppercase
}

#hakkv #phase1 .right-pe .body {
  flex: 1 1 100%;
  margin-top: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: .0425em;
  text-align: justify
}

#hakkv #phase1 .right-pe .conity {
  margin-top: 12px
}

#hakkv #phase1 .right-pe .conity .amaro {
  position: relative;
  display: flex;
  width: 100%
}

#hakkv #phase1 .right-pe .conity .amaro .osp {
  background-color: #b5993f;
  flex: 4px 0 0;
  height: 4px;
  border-radius: 4px
}

#hakkv #phase1 .right-pe .conity .amaro .gonz {
  background-color: #923730;
  flex: 8px 0 0;
  height: 4px;
  border-radius: 4px;
  margin: 0 4px
}

#hakkv #phase1 .right-pe .conity .amaro .ramb {
  background-color: #776b70;
  flex: 100% 1 1;
  height: 4px;
  border-radius: 4px
}

#hakkv #phase1 .right-pe .conity .buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0
}

#hakkv #phase1 .right-pe .conity .buttons-container .amaro {
  display: none
}

#hakkv #phase1 .right-pe .conity .buttons-container a {
  font-family: Poppins, sans-serif;
  color: #bdc0c2;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  transition: .2s
}

#hakkv #phase1 .right-pe .conity .buttons-container a:hover {
  color: #fff
}

#hakkv #phase1 .right-pe .conity .buttons-container .primary-button {
  flex: 0 1 256px;
  margin-left: 12px
}

#hakkv #bg-pareds {
  background-repeat: no-repeat;
  transition: background-image .2s linear;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1400px;
  border-top: 4px solid #080d16
}

#hakkv #bg-pareds .shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(190deg, transparent, rgba(0, 0, 0, .5) 75%)
}

#hakkv #tokinf {
  height: auto;
  overflow: hidden;
  background-image: linear-gradient(360deg, #404148, #0a001a, #000c17)
}

#hakkv #tokinf>.container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

#hakkv #tokinf .text-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 720px;
  height: 2px;
  top: 28px
}

#hakkv #tokinf .text-container .main-header {
  display: flex;
  align-items: flex-end
}

#hakkv #tokinf .text-container .main-header .title-icon {
  background-size: contain;
  width: 117.3333333333px;
  height: 80px;
  margin-right: 12px
}

#hakkv #tokinf .text-container .main-header .titles-container {
  width: 100%
}

#hakkv #tokinf .text-container .main-header .titles-container .title {
  font-family: Titles;
  font-size: 62px;
  font-style: italic
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container {
  display: flex;
  align-items: center;
  margin-top: -12px
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .subtitle {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  color: #a7a7a7;
  white-space: nowrap
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro {
  margin-left: 24px
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro {
  position: relative;
  display: flex;
  width: 100%
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro .osp {
  background-color: #b5993f;
  flex: 4px 0 0;
  height: 4px;
  border-radius: 4px
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro .gonz {
  background-color: #923730;
  flex: 8px 0 0;
  height: 4px;
  border-radius: 4px;
  margin: 0 4px
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro .ramb {
  background-color: #776b70;
  flex: 100% 1 1;
  height: 4px;
  border-radius: 4px
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro .seft {
  position: absolute;
  top: -4px;
  right: -10px;
  width: 13px;
  height: 13px;
  border: 4px solid #76002a;
  transform: rotate(45deg)
}

#hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .amaro .seft {
  left: -10px
}

#hakkv #team {
  background: linear-gradient(164deg, rgb(20 24 29) 0, rgb(28 28 28) 35%);
  height: auto;
  padding-bottom: 20px
}

#hakkv #team .header .title {
  font-family: Titles;
  color: #fff;
  font-size: 50px
}

#hakkv #team .header .subtitle {
  font-family: Poppins, sans-serif;
  color: #0083be;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase
}

#hakkv #team>.container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap
}

#hakkv #footer {
  background-color: #14181d;
  height: auto;
  padding: 36px 0
}

#hakkv #footer .header {
  display: flex;
  align-items: center;
  padding: 24px 0
}

#hakkv #footer .header .logo-container {
  display: flex;
  align-items: center
}

#hakkv #footer .header .logo-container .logotype {
  background-size: contain;
  width: 220px;
  height: 80px;
  flex-shrink: 0
}

#hakkv #footer .header .amaro {
  margin: 0 22px
}

#hakkv #footer .header .amaro {
  position: relative;
  display: flex;
  width: 100%
}

#hakkv #footer .header .amaro .osp {
  background-color: #b5993f;
  flex: 4px 0 0;
  height: 4px;
  border-radius: 4px
}

#hakkv #footer .header .amaro .gonz {
  background-color: #923730;
  flex: 8px 0 0;
  height: 4px;
  border-radius: 4px;
  margin: 0 4px
}

#hakkv #footer .header .amaro .ramb {
  background-color: #776b70;
  flex: 100% 1 1;
  height: 4px;
  border-radius: 4px
}

#hakkv #footer .header .sico {
  display: flex;
  align-items: center
}

#hakkv #footer .header .sico .icon {
  position: relative;
  background-size: contain;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0) saturate(7492%) hue-rotate(307deg) brightness(106%) contrast(101%);
  width: 44px;
  height: 44px;
  transition: transform .2s
}

#hakkv #footer .header .sico .icon:not(:first-child) {
  margin-left: 10px
}

#hakkv #footer .header .sico .icon a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

#hakkv #footer .header .sico .icon:hover {
  transform: scale(1.25)
}

#hakkv #footer .header .sico .icon.instag {
  background-image: url(./svg/icon_instagram.svg)
}

#hakkv #footer .header .sico .icon.discord {
  background-image: url(./svg/icon_discord.svg)
}

#hakkv #footer .header .sico .icon.twitter {
  background-image: url(./svg/icon_twitter.svg)
}

#hakkv #footer .header .sico .icon.youtube {
  background-image: url(./svg/icon_youtube.svg)
}

#hakkv #footer .header .sico .icon.facebook {
  background-image: url(./svg/icon_facebook.svg)
}

#hakkv #footer .header .sico .icon.telegram {
  background-image: url(./svg/icon_telegram.svg)
}

#hakkv #footer .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  letter-spacing: .0425em;
  text-transform: uppercase
}

#hakkv #footer .footer .text {
  color: #776b70;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0
}

#hakkv #footer .footer .hakkav_autor {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12.5px;
  letter-spacing: 0;
  font-weight: 800;
  cursor: pointer
}

#hakkv #footer .footer .hakkav_autor:hover:after {
  animation: upAndDown 1s infinite cubic-bezier(.165, .84, .44, 1) forwards
}

@keyframes upAndDown {
  0% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(-5px)
  }
  100% {
    transform: translateY(0)
  }
}

#hakkv #footer .footer .hakkav_autor:after {
  content: "";
  display: inline-block;
  margin-left: 10px;
  background: url(https://i.imgur.com/GM703TW.png) no-repeat 0 0;
  background-size: contain;
  width: 38px;
  height: 38px
}

@media only screen and (max-width:1500px) {
  #hakkv #phase1 {
    min-height: 791px;
    height: auto
  }
  #hakkv #phase1>.container {
    flex-direction: column
  }
  #hakkv #phase1 .previd {
    margin: 40px 0 28px 0
  }
  #hakkv #phase1 .right-pe {
    height: auto;
    margin: 0 48px 48px 48px;
    text-align: justify;
    text-align-last: center
  }
  #hakkv #tokinf {
    height: auto
  }
  #hakkv #tokinf>.container {
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
  #hakkv #tokinf .text-container {
    top: 0;
    width: calc(100% - (48px * 2));
    height: auto;
    margin: 0 48px
  }
}

@media only screen and (max-width:1500px) {
  #hakkv #team>.container {
    padding: 0 48px
  }
  #hakkv #team .header>.container {
    padding: 0 48px
  }
}

@media only screen and (max-width:1500px) {
  #hakkv #footer>.container {
    padding: 0 48px
  }
}

@media only screen and (max-width:1132px) {
  #hakkv #bg-pareds {
    height: auto
  }
}

@media only screen and (max-width:810px) {
  #hakkv #luzpm {
    margin-top: 60px;
    height: 90vh
  }
}

@media only screen and (max-width:810px) {
  #hakkv #phase1 {
    height: auto;
    padding-bottom: 20px
  }
  #hakkv #phase1>.container {
    flex-direction: column;
    padding: 20px 48px
  }
  #hakkv #phase1:before {
    animation: none
  }
  #hakkv #phase1 .previd {
    transform: scale(.6, .6);
    margin: -92px 0 -50px 0
  }
  #hakkv #phase1 .right-pe {
    width: 100%;
    height: auto
  }
  #hakkv #phase1 .right-pe .title {
    text-align: center;
    margin-bottom: 12px;
    white-space: nowrap
  }
  #hakkv #phase1 .right-pe .title {
    font-size: 38px
  }
  #hakkv #phase1 .previd {
    position: relative;
    flex-shrink: 0;
    left: -4px
  }
}

@media only screen and (max-width:810px)and (min-width:375px) {
  #hakkv #phase1 .right-pe .title {
    font-size: calc(30px + 24 * ((100vw - 375px)/ 425))
  }
}

@media only screen and (max-width:810px)and (min-width:800px) {
  #hakkv #phase1 .right-pe .title {
    font-size: 62px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #phase1 .right-pe .body {
    line-height: 26px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #phase1 .right-pe .conity .amaro {
    display: none
  }
  #hakkv #phase1 .right-pe .conity .buttons-container {
    flex-direction: column
  }
  #hakkv #phase1 .right-pe .conity .buttons-container .amaro {
    display: flex;
    margin: 12px 0
  }
  #hakkv #phase1 .right-pe .conity .buttons-container .primary-button {
    flex: initial;
    width: 100%;
    margin-top: 6px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #bg-pareds {
    height: auto;
    padding: 48px 0;
    background-attachment: initial;
    background-position: initial;
    background-repeat: initial;
    background-size: cover
  }
}

@media only screen and (max-width:810px) {
  #hakkv #tokinf {
    padding-top: 0
  }
  #hakkv #tokinf>.container {
    flex-direction: column;
    padding: 20px 48px
  }
  #hakkv #tokinf .text-container {
    order: 0;
    width: 100%
  }
  #hakkv #tokinf .text-container .main-header .title-icon {
    display: none
  }
  #hakkv #tokinf .text-container .main-header .titles-container .title {
    font-size: 28.5px
  }
}

@media only screen and (max-width:810px)and (min-width:375px) {
  #hakkv #tokinf .text-container .main-header .titles-container .title {
    font-size: calc(28.5px + 33.5 * ((100vw - 375px)/ 425))
  }
}

@media only screen and (max-width:810px)and (min-width:800px) {
  #hakkv #tokinf .text-container .main-header .titles-container .title {
    font-size: 62px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #tokinf .text-container .main-header .titles-container .subtitle-container {
    margin-top: 0
  }
  #hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .subtitle {
    font-size: 18px
  }
}

@media only screen and (max-width:810px)and (min-width:375px) {
  #hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .subtitle {
    font-size: calc(18px + 8 * ((100vw - 375px)/ 425))
  }
}

@media only screen and (max-width:810px)and (min-width:800px) {
  #hakkv #tokinf .text-container .main-header .titles-container .subtitle-container .subtitle {
    font-size: 26px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #team .header {
    padding-top: 20px
  }
  #hakkv #team .header>.container {
    flex-direction: column;
    padding: 0 48px
  }
  #hakkv #team .header .title {
    text-align: center
  }
  #hakkv #team .header .title {
    font-size: 21.75px
  }
}

@media only screen and (max-width:810px)and (min-width:375px) {
  #hakkv #team .header .title {
    font-size: calc(21.75px + 28.25 * ((100vw - 375px)/ 425))
  }
  #hakkv #team>.container {
    flex-direction: column;
    padding: 0 100px
  }
}

@media only screen and (max-width:810px)and (min-width:800px) {
  #hakkv #team .header .title {
    font-size: 50px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #team .header .subtitle {
    text-align: center
  }
  #hakkv #team .header .subtitle {
    font-size: 10px
  }
}

@media only screen and (max-width:810px)and (min-width:375px) {
  #hakkv #team .header .subtitle {
    font-size: calc(10px + 6 * ((100vw - 375px)/ 425))
  }
}

@media only screen and (max-width:810px)and (min-width:800px) {
  #hakkv #team .header .subtitle {
    font-size: 16px
  }
}

@media only screen and (max-width:810px) {
  #hakkv #footer {
    padding-bottom: 20px
  }
  #hakkv #footer .header {
    flex-direction: column
  }
  #hakkv #footer .header .amaro {
    display: none
  }
  #hakkv #footer .header .sico {
    margin-top: 20px
  }
  #hakkv #footer .footer {
    flex-direction: column;
    align-items: center;
    text-align: center
  }
  #hakkv #footer .footer .text {
    font-size: 8px
  }
  #hakkv #footer .footer .hakkav_autor {
    margin-top: 20px
  }
}

.alert {
  padding: 12px;
  background-color: #f44336;
  color: #fff;
  opacity: 1;
  transition: opacity .6s;
  margin-bottom: 15px;
  font-family: Poppins, sans-serif;
  font-size: 15px
}

.alert.success {
  background-color: #04aa6d
}

.alert.info {
  background-color: #3082c9
}

.alert.warning {
  background-color: #c98c30
}

.closebtn {
  margin-left: 15px;
  color: #fff;
  font-weight: 700;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: .3s
}

.closebtn:hover {
  color: #000
}

.txamb {
  font-family: Titles;
  color: #fff;
  font-size: 50px
}

.sbxab {
  font-family: Poppins, sans-serif;
  color: #0083be;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase
}

.socials-vp {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 64px;
	background-color: #1a202c;
	border-radius: 10px;
	margin-top: 24px;
	padding: 0 24px;
}
.socials-vp .sico {
	display: flex;
	align-items: center;
}
.socials-vp .sico .icon {
	position: relative;
	background-size: contain;
	width: 44px;
	height: 44px;
	transition: transform 0.2s;
}
.socials-vp .sico .icon a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.socials-vp .sico .icon svg {
	width: 44px;
	height: 44px;
}
.socials-vp .sico .icon svg path {
	transition: 0.2s;
}
.socials-vp .sico .icon:not(:first-child) {
	margin-left: 6px;
}
.cls-1 {
	fill: #fff;
	fill-rule: evenodd;
}
.barket {
	display: flex;
	align-items: center;
	justify-content: left;
	margin-bottom: 6px;
}
.barket .aaron {
	font-family: Poppins, sans-serif;
	font-weight: 700;
	font-size: 18px;
	line-height: 26px;
	text-transform: uppercase;
	font-style: italic;
	margin-left: 12px;
}
.roadmap h2[aex] {
	font-size: 62px;
	text-align: center;
	color: #c99666;
	font-family: Titles;
	text-transform: uppercase;
	margin-top: 60px;
}
@media (min-width: 0px) and (max-width: 1279px) {
	.roadmap h2[aex] {
		margin: 0;
		font-size: 30px;
		line-height: 35px;
	}
}
@media (min-width: 0px) and (max-width: 1279px) {
	.roadmap[aex] {
		width: 90%;
		margin-left: 5%;
	}
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}
::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
	background-color: #14181d;
	background-image: -webkit-linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.2) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.2) 50%,
		rgba(255, 255, 255, 0.2) 75%,
		transparent 75%,
		transparent
	);
}
.roadmap[villap] {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.roadmap .milestones[villap] {
	flex-direction: column;
	width: 70%;
	position: relative;
	padding: 20px;
	margin: 0 auto;
}
@media (min-width: 0px) and (max-width: 1279px) {
	.roadmap .milestones[villap] {
		padding: 0;
		width: 92%;
	}
}
.roadmap .milestones.block[villap] {
	width: 100%;
	max-width: 1320px;
}
.roadmap .milestones[villap]:after {
	content: "";
	position: absolute;
	width: 4px;
	background-color: #3c8db9;
	top: 71px;
	bottom: 71px;
	left: 50%;
	margin-left: -2px;
}
@media (min-width: 0px) and (max-width: 767px) {
	.roadmap .milestones[villap]:after {
		top: 60px;
		bottom: 60px;
		width: 8px;
		margin-left: -4px;
	}
}
.roadmap .event-wrapper[villap] {
	padding: 10px 0;
	display: flex;
}
.roadmap .event-wrapper[villap]:nth-child(2n) {
	justify-content: flex-start;
}
.roadmap .event-wrapper:nth-child(2n) .event[villap] {
	flex-direction: row-reverse;
}
.roadmap .event-wrapper:nth-child(2n) .event .description[villap] {
	text-align: right;
}
.roadmap .event-wrapper[villap]:nth-child(odd) {
	justify-content: flex-end;
}
.roadmap .event[villap] {
	display: flex;
	align-items: center;
	overflow: hidden;
	border-radius: 15px;
	min-height: 100px;
	background-color: #282e36;
	width: 66.8%;
}
@media (max-width: 767px) {
	.roadmap .event[villap] {
		width: 90%;
		min-height: 80px;
	}
}
@media (max-width: mobilepx) {
	.roadmap .event[villap] {
		width: 90%;
		min-height: 80px;
	}
}
.roadmap .event .date[villap] {
	background-color: #14181d;
	font-family: Titles, sans-serif;
	height: 100%;
	width: 23.1%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	padding: 20px;
	font-size: 30px;
	line-height: 2px;
	text-align: left;
}
@media (min-width: 1367px) and (max-width: 1600px) {
	.roadmap .event .date[villap] {
		font-size: 24px;
	}
}
@media (min-width: 0px) and (max-width: 767px) {
	.roadmap .event .date[villap] {
		font-size: 17px;
		line-height: 19px;
		width: 39%;
		font-size: 30px;
	}
}
@media (min-width: 0px) and (max-width: 767px) {
	.roadmap .event .date p[villap] {
		font-size: 15px;
		margin-bottom: 10px;
	}
}
@media (max-width: 380px) {
	.roadmap .event .date[villap] {
		font-size: 25px;
	}
	.roadmap .event .date p[villap] {
		text-align: center;
	}
}
.roadmap .event .date .month[villap] {
	font-family: Titles, sans-serif;
	text-transform: uppercase;
	color: #3c8db9;
}
.roadmap .event .anchor[villap] {
	width: 4%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: 767px) {
	.roadmap .event .anchor[villap] {
		width: 11%;
	}
}
@media (max-width: mobilepx) {
	.roadmap .event .anchor[villap] {
		width: 11%;
	}
}
.roadmap .event .anchor .circle[villap] {
	z-index: 10;
	background-color: #fff;
	width: 20px;
	min-width: 20px;
	height: 20px;
	border: 5px solid #0087e2;
	border-radius: 20px;
}
.roadmap .event .description[villap] {
	flex: 1 1;
	font-size: 11px;
	text-align: left;
	padding: 10px;
}
.roadmap .event .description p[villap] {
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 600;
}
@media (min-width: 0px) and (max-width: 767px) {
	.roadmap .event .description p[villap] {
		font-size: 13px;
	}
}
@media (min-width: 0px) and (max-width: 767px) {
	.roadmap .event .description p[villap] {
		font-size: 10px;
	}
}
ul {
	list-style-type: none;
	list-style-position: outside;
	list-style-image: none;
}
h4 {
	font-size: 20px;
	line-height: 25px;
}
@media (min-width: 1367px) and (max-width: 1600px) {
	p {
		font-size: 12px;
	}
}
h2 {
	font-size: 30px;
	line-height: 32px;
	font-family: Poppins, sans-serif;
	margin: 30px 0;
}
p {
	font-size: 15px;
	line-height: 20px;
	color: #bdc0c2;
}
.wrapper {
	display: flex;
	justify-content: center;
}
.cta {
	display: flex;
	text-decoration: none;
	font-family: Poppins, sans-serif;
	font-size: 22px;
	color: #fff;
	background: #282e36;
	transition: 1s;
	box-shadow: 6px 6px 0 #000;
	transform: skewX(-15deg);
	font-weight: 900;
	padding-right: 15px;
	padding-left: 15px;
}
.cta:focus {
	outline: 0;
}
.cta:hover {
	transition: 0.5s;
	box-shadow: 10px 10px 0 #0087e2;
}
.cta span:nth-child(2) {
	transition: 0.5s;
	margin-right: 0;
}
.cta:hover span:nth-child(2) {
	transition: 0.5s;
	margin-right: 45px;
}
path.one {
	transition: 0.4s;
	transform: translateX(-60%);
}
path.two {
	transition: 0.5s;
	transform: translateX(-30%);
}
.cta:hover path.three {
	animation: color_anim 1s infinite 0.2s;
}
.cta:hover path.one {
	transform: translateX(0);
	animation: color_anim 1s infinite 0.6s;
}
.cta:hover path.two {
	transform: translateX(0);
	animation: color_anim 1s infinite 0.4s;
}
@keyframes color_anim {
	0% {
		fill: #fff;
	}
	50% {
		fill: #0087e2;
	}
	100% {
		fill: #fff;
	}
}
.cls-1 {
	fill: #fff;
	fill-rule: evenodd;
}
.sico .icon {
	position: relative;
	background-size: contain;
	filter: brightness(0) saturate(100%) invert(100%) sepia(0) saturate(7492%)
		hue-rotate(307deg) brightness(106%) contrast(101%);
	width: 44px;
	height: 44px;
	transition: transform 0.2s;
}
.sico .icon:not(:first-child) {
	margin-left: 10px;
}
#hakkv #tokinf .text-container {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 720px;
	height: 2px;
	top: 28px;
}
#hakkv #tokinf .text-container .main-header {
	display: flex;
	align-items: flex-end;
}
.card {
	position: relative;
	width: 300px;
	height: 380px;
	background: #282e36;
	overflow: hidden;
	clip-path: polygon(
		0 0,
		calc(100% - 20px) 0,
		100% 20px,
		100% 100%,
		20px 100%,
		0 calc(100% - 20px)
	);
}
.card .circle {
	position: absolute;
	top: -58px;
	left: 50%;
	width: 286px;
	height: 286px;
	transform: translateX(-50%);
	clip-path: circle();
}
.card .circle::before {
	content: "";
	position: absolute;
	top: -8px;
	left: -16px;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 0 20px rgb(0 131 190);
	border-radius: 50%;
	z-index: 2;
	pointer-events: none;
}
.circle .imgBox {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	width: 306px;
	height: 228px;
}
.imgBox img {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
	transition: all 0.5s;
}
.imgBox img:hover {
	transform: scale(1.2);
}
.card .content {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 20px 30px;
}
.content .fa-linkedin {
	padding: 4px 4px;
	color: #fff;
	background: #0077b5;
	border-radius: 2px;
}
.content h3 {
	font-size: 22px;
	color: #fff;
	margin-top: 6px;
	margin-bottom: 4px;
	font-family: Titles, sans-serif;
}
.content .text_vp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.content .text_vp h4 {
	color: #45a1bc;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 600;
}
.content .text_vp .fa-address-book {
	color: #8d949f;
}
#hakkv #tokinf .text-container {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 720px;
	height: 2px;
	top: 28px;
}
@media (min-width: 1400px) {
	.partn_xd {
		max-width: 1320px;
	}
}
@media (min-width: 1200px) {
	.partn_xd {
		max-width: 1140px;
	}
}
@media (min-width: 992px) {
	.partn_xd {
		max-width: 960px;
	}
}
@media (min-width: 768px) {
	.partn_xd {
		max-width: 720px;
	}
}
@media (min-width: 576px) {
	.partn_xd {
		max-width: 620px;
	}
}
.partn_xd {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}
.kur_title {
	padding: 80px 0 2px 32px;
	overflow: hidden;
	position: relative;
	font-family: Titles;
}
.kur_title h1 {
	font-size: 80px;
	font-weight: 700;
	margin: 0;
	text-transform: uppercase;
	line-height: 1;
	color: #fff;
}
.par_vp {
	letter-spacing: 0;
}
.ic-subtitle {
	position: absolute;
	bottom: 2px;
	left: 6px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	color: #fff;
	transform: rotate(-90deg) translateX(0) !important;
	transform-origin: left center;
}
.ic-subtitle span {
	color: #42bed2;
}
.mt-partner {
	margin-top: 1.5rem !important;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 600;
}
.mt-bgpar {
	margin-top: 3rem !important;
}
.justify-content-end {
	justify-content: flex-end !important;
}
.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}
@media (min-width: 768px) {
	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}
}
.col-6 {
	flex: 0 0 auto;
	width: 50%;
}
.row > * {
	flex-shrink: 0;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}
.ic-partner {
	display: flex;
	height: 90px;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	padding: 15px 20px;
	box-sizing: border-box;
	background-color: #3c5a8523;
	text-align: center;
	transition: 0.3s cubic-bezier(0.21, 0.04, 0, 1.07);
	position: relative;
	margin-bottom: 24px;
	transition: 0.3s cubic-bezier(0.21, 0.04, 0, 1.07);
	filter: drop-shadow(0 3px 7px rgba(14, 8, 18, 0.44));
}
.ic-partner::after,
.ic-partner::before {
	position: absolute;
	content: "";
	top: 0;
	height: 100%;
	width: 45px;
	border: 2px solid #42bed240;
	transition: all 0.4s;
}
.ic-partner::before {
	left: 0;
	border-radius: 6px 0 0 6px;
	border-right: none;
}
.ic-partner::after {
	right: 0;
	border-radius: 0 6px 6px 0;
	border-left: none;
}
.ic-partner:hover {
	transform: translateY(-10px);
	clip-path: polygon(
		0 0,
		calc(100% - 16px) 0,
		100% 16px,
		100% 100%,
		16px 100%,
		0 calc(100% - 16px)
	);
}
.ic-partner:hover::after {
	border-top: 0;
}
.ic-partner:hover::before {
	border-bottom: 0;
}
.ic-partner img {
	height: auto;
	max-height: 55px;
	object-fit: contain;
}
.ic-subtitle {
	position: absolute;
	bottom: 2px;
	left: 6px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	color: #fff;
	transform: rotate(-90deg) translateX(0) !important;
	transform-origin: left center;
}
.ic-subtitle span {
	color: #42bed2;
}
@media (max-width: 1024px) {
	.kur_title h1 {
		font-size: 58px;
	}
	.ic-subtitle {
		letter-spacing: 0.1em;
		font-size: 12px;
		bottom: -8px;
	}
}
@media (max-width: 767px) {
	.kur_title {
		padding-left: 30px;
	}
	.kur_title h1 {
		font-size: 42px;
	}
	.ic-subtitle {
		left: 12px;
		letter-spacing: 0.1em;
		font-size: 9px;
		bottom: -8px;
	}
}
.content-contract[contvp] {
	background: #0414347a;
	height: 50px;
	border-radius: 26px;
	width: 400px;
	margin-top: 30px;
}
.contract-position[contvp] {
	width: 344px;
}
.tx-contract {
	font-family: Poppins, sans-serif;
	font-weight: 600;
	color: #f4f4f4;
	font-size: 15px;
}
.contract-icon[contvp] {
	height: 48px;
	background: #1a202c;
	border-radius: 5px 26px 26px 5px;
	width: 56px;
}
.far[contvp] {
	color: #c99666;
	width: auto;
	font-size: 25px;
}
#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
}
#loading {
	background-color: #242f3f;
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 9999;
	margin-top: 0;
	top: 0;
}
.cssload-loader {
	width: auto;
	height: 100vh;
	border: dotted #aaa 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cssload-loader-inner {
	text-align: center;
	background-size: contain;
	height: 20%;
	width: 20%;
}

*,
:after,
:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #14181d;
  min-width: 124px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2)
}

.dropdown-content a {
  color: black;
  padding: 8px 10px;
  text-decoration: none;
  display: block
}

.dropdown-content a:hover {
  background-color: #282e36
}

.dropdown:hover .dropdown-content {
  display: block
}

.slider__navi {
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	z-index: 999;
}
.slider__navi a {
	display: block;
	height: 6px;
	width: 20px;
	margin: 20px 0;
	text-indent: -9999px;
	box-shadow: none;
	border: 0;
	background: rgba(0, 0, 0, 0.2);
}
.slider__navi a.active {
	background: #fff;
}
.flex__container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	-webkit-flex-flow: row wrap;
	-moz-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	-o-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	-o-justify-content: flex-start;
	justify-content: flex-start;
	height: 100vh;
	width: 100%;
	z-index: 1;
}
.flex__container.flex--active {
	z-index: 2;
}
.text--sub {
	font-size: 34px;
	letter-spacing: 0.5rem;
	text-transform: uppercase;
	margin-bottom: 40px;
	font-family: Titles, sans-serif;
}
.text--big {
	font-family: Titles, sans-serif;
	font-size: 5.2em;
	font-weight: 700;
	line-height: 110px;
	margin-left: -8px;
}
.text--normal {
	font-size: 18px;
	color: rgba(255, 255, 255, 0.8);
	line-height: 22px;
	margin-top: 25px;
	font-family: Poppins, sans-serif;
}
.flex__item {
	height: 100vh;
	color: #fff;
	transition: transform 0.1s linear;
}
.flex__item--left {
	display: flex;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	align-items: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	width: 65%;
	transform-origin: left bottom;
	transition: transform 0.1s linear 0.4s;
	opacity: 0;
	position: relative;
	overflow: hidden;
}
.flex__item--right {
	width: 35%;
	transform-origin: right center;
	transition: transform 0.1s linear 0s;
	opacity: 0;
}
.flex--active .flex__item--left,
.flex--active .flex__item--right {
	opacity: 1;
}
.farmob .flex__item--left {
	background: #7e5237;
}
.farmob .flex__item--right {
	background: #b5a75a;
}
.dailyqt .flex__item--left {
	background: #2e4b74;
}
.dailyqt .flex__item--right {
	background: #c9c9c9;
}
.battry .flex__item--left {
	background: #cb672d;
}
.battry .flex__item--right {
	background: #cbc5ac;
}
.lanwr .flex__item--left {
	background: #363535;
}
.lanwr .flex__item--right {
	background: #379545;
}
.flex__content {
	margin-left: 80px;
	margin-top: -280px;
	width: 55%;
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: transform 0.2s linear 0.2s, opacity 0.1s linear 0.2s;
}
.ober_gap {
	position: absolute;
	bottom: 240px;
	right: 5%;
	max-height: 34vw;
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity 0.43s 0.6s,
		transform 0.4s 0.65s cubic-bezier(0, 0.88, 0.4, 0.93);
}
@media only screen and (max-width: 810px) and (min-width: 375px) {
	.ober_gap {
		bottom: 250px;
		right: 6%;
		max-height: 47vw;
		opacity: 1;
	}
}
.flex__container.animate--start .flex__content {
	transform: translate3d(0, -200%, 0);
	opacity: 0;
}
.flex__container.animate--start .ober_gap {
	transform: translate3d(-200px, 0, 0);
	opacity: 0;
}
.container-16 {
	margin-top: 50px;
	padding: 0 0 80px 0;
}
.w-container {
	margin-left: auto;
	margin-right: auto;
	max-width: 940px;
}
.grid-10 {
	position: relative;
	margin-top: 20px;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width: 810px) and (min-width: 375px) {
	.w-layout-grid {
		display: -ms-grid;
		display: grid;
		grid-auto-columns: 1fr;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
		grid-row-gap: 16px;
		grid-column-gap: 16px;
	}
}
.w-layout-grid {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-row-gap: 16px;
	grid-column-gap: 16px;
}
.hw_nfts {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}
*,
::after,
::before {
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: currentColor;
}
img {
	border-style: solid;
}
img {
	display: block;
	vertical-align: middle;
}
img {
	max-width: 100%;
	height: auto;
}
.hwngs {
	width: 100%;
}
@media (min-width: 640px) {
	.hwngs {
		max-width: 640px;
	}
}
@media (min-width: 768px) {
	.hwngs {
		max-width: 768px;
	}
}
@media (min-width: 1024px) {
	.hwngs {
		max-width: 1024px;
	}
}
@media (min-width: 1280px) {
	.hwngs {
		max-width: 1280px;
	}
}
@media (min-width: 1536px) {
	.hwngs {
		max-width: 1536px;
	}
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.-mx-4 {
	margin-left: -1rem;
	margin-right: -1rem;
}
.flex {
	display: flex;
}
.w-1\/2 {
	width: 50%;
}
.flex-wrap {
	flex-wrap: wrap;
}
.items-center {
	align-items: center;
    min-width: 80%;
}
.justify-center {
	justify-content: center;
}
.p-4 {
	padding: 1rem;
}
.px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
@media (min-width: 768px) {
	.md\:-mx-8 {
		margin-left: -2rem;
		margin-right: -2rem;
	}
	.md\:w-1\/4 {
		width: 25%;
	}
	.md\:px-0 {
		padding-left: 0;
		padding-right: 0;
	}
}
.hwngs {
	max-width: 1120px !important;
}
@media (min-width: 768px) {
	.md\:w-1\/4 {
		width: 20%;
	}
}
@media only screen and (max-width: 1518px) and (min-width: 632px) {
	#hakkv #luzpm {
		position: relative;
		width: 100%;
		height: calc(104vh - 50px);
		margin-top: 60px;
		background-color: #0c161c;
	}
}

.nav-text {
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: .7px;
  font-weight: 500;
  font-family: Titles, sans-serif;
}
.card-name{
  font-size: 18px;
  line-height: 22px;
  font-family: Titles, sans-serif;
  color: #fff;
  text-align: left;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card{

  border-radius: 10px;
  background: linear-gradient(
180deg, #2d3640 0, #29384a);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);

}

.card-rocket {
  border-radius: 10px;
  background: linear-gradient(
180deg, #2d3640 0, #29384a);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.text-level{
  font-size: 14px;
  line-height: 18px;
  font-family: Poppins, sans-serif!important;
  color: #fff;
  text-align: center;
}

.exp-rocket{
  font-size: 16px;
    line-height: 26px;
    font-family: Poppins, sans-serif!important;
    color: #c19e5d;
    text-align: left;
    font-weight: 600!important;
}